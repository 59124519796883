import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: { chatId: -1 },
    error: null,
};

const prepare = (payload, meta, error) => {
    return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        CREATE_NEW_CHAT_ROOM_PENDING: {
            reducer: (state, { payload }) => {
                state.loading = payload.loading;
            },
            prepare,
        },
        CREATE_NEW_CHAT_ROOM_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.loading = false;
            },
            prepare,
        },

        CREATE_NEW_CHAT_ROOM_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.loading = false;
            },
            prepare,
        },
        SELECTED_CHAT_SESSION: {
            reducer: (state, { payload }) => {
                const prevState = state.data;
                state.data = { ...prevState, chatId: payload.chatId };
            },
            prepare,
        },
        RESET_SELECTED_CHAT_SESSION: {
            reducer: (state, { payload }) => {
                state.data.chatId = -1;
            },
            prepare,
        },
        RESET_CHAT_SESSION: {
            reducer: (state, { payload }) => {
                state.data = initialState.data;
                state.loading = false;
            },
            prepare,
        },
    },
    // extraReducers: (builder) => {
    //     builder.addCase('persist/REHYDRATE', (state, action) => {
    //         // Ensure to correctly merge or set the rehydrated state
    //         if (action.payload && action.payload.chat) {
    //             return {
    //                 ...state,
    //                 ...action.payload.chat,
    //             };
    //         }
    //     });
    // },
});

export const {
    CREATE_NEW_CHAT_ROOM_PENDING,
    CREATE_NEW_CHAT_ROOM_SUCCESS,
    CREATE_NEW_CHAT_ROOM_ERROR,
    SELECTED_CHAT_SESSION,
    RESET_CHAT_SESSION,
    RESET_SELECTED_CHAT_SESSION,
} = chatSlice.actions;

export default chatSlice.reducer;

import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import { formatAccountTitle, formatCurrencyAmount } from 'utils/helpers';

const DropDownTableRowMobile = ({
    row,
    hasTax,
    index,
    handleTaxClick,
    handleTaxChangeCheckbox,
    handleEditClickSecondTable,
    editIdSecondTable,
    rowIndex,
    handleRemoveTransactionItem,
    isEdit,
}) => {
    return (
        <Stack
            key={row.id}
            gap={1}
            sx={{
                p: 2,
            }}
            onClick={() =>
                isEdit &&
                handleEditClickSecondTable(row, index, editIdSecondTable)
            }
        >
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Description</Typography>
                    <Typography color="text.primary">
                        {row?.description}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Category</Typography>
                    <Typography color="text.primary">
                        <Typography>
                            {formatAccountTitle(row?.account)}
                        </Typography>
                    </Typography>
                </Stack>
            </Stack>
            {/* <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Amount</Typography>
                    <Typography>
                        ${' '}
                        {formatCurrencyAmount(
                            row?.isThisTax
                                ? 0
                                : (+row?.price || 0) +
                                      (row?.taxAmount || 0) / row?.quantity,
                        )}
                    </Typography>
                </Stack>
            </Stack> */}
            {/* <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Quantity</Typography>
                    <Typography color="text.primary">
                        {parseInt(row?.quantity || 0)}
                    </Typography>
                </Stack>
            </Stack> */}
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Total Amount</Typography>
                    <Typography color="text.primary">
                        ${' '}
                        {formatCurrencyAmount(
                            row?.isThisTax
                                ? 0
                                : (+row?.item_total || 0) +
                                      (row?.taxAmount || 0),
                        )}
                    </Typography>
                </Stack>
            </Stack>
            {isEdit && (
                <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography>
                        <FormControlLabel
                            key={index}
                            control={
                                hasTax === index || hasTax === null ? (
                                    <Checkbox
                                        disabled={!isEdit}
                                        size="small"
                                        checked={row?.isThisTax}
                                        onChange={(event) =>
                                            handleTaxClick(event, index)
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                ) : (
                                    <Checkbox
                                        disabled={!isEdit}
                                        size="small"
                                        checked={row?.isTaxed}
                                        onChange={(event) =>
                                            handleTaxChangeCheckbox(
                                                event,
                                                index,
                                            )
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                )
                            }
                            label={
                                hasTax === index || hasTax === null ? (
                                    <Typography>
                                        <i>This is tax to allocate</i>
                                    </Typography>
                                ) : (
                                    <Typography>
                                        $ {formatCurrencyAmount(row?.taxAmount)}
                                    </Typography>
                                )
                            }
                        />
                    </Typography>
                    {isEdit && (
                        <IconButton
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleRemoveTransactionItem(
                                    rowIndex,
                                    index,
                                    false,
                                );
                            }}
                        >
                            <Trash />
                        </IconButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

export default DropDownTableRowMobile;

import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useCoreService = () => {
    const { get, patch, isLoading } = useAxios();

    const getIndustries = useCallback(() => get('/core/industry/'), [get]);

    const getCustomerChartsOfAccountsForEdit = useCallback(
        (accountId, companyId, nonAccountantMode) =>
            get(
                nonAccountantMode
                    ? `/core/customer_chart_of_accounts/get_accounts_for_edit/?company_id=${companyId}&current_account=${accountId}&non_accountant_mode=${nonAccountantMode}`
                    : `/core/customer_chart_of_accounts/get_accounts_for_edit/?company_id=${companyId}&non_accountant_mode=${nonAccountantMode}`,
            ),
        [get],
    );

    const getTransactionStatusOptions = useCallback(
        (companyId, nonAccountantMode) =>
            get(
                `/core/customer_chart_of_accounts/get_accounts_for_edit/?company_id=${companyId}&non_accountant_mode=${nonAccountantMode}`,
            ),
        [get],
    );

    const getListOfParentsAccounts = useCallback(
        (companyId) =>
            get(
                `/core/customer-chart-of-accounts/${companyId}/list_parent_accounts/`,
            ),
        [get],
    );

    const getCoaAccount = useCallback(
        (accountId) => get(`/core/customer-chart-of-account/${accountId}/`),
        [get],
    );

    const getActiveAccounts = useCallback(
        (companyId, pageNumber) =>
            get(
                `/core/customer_chart_of_accounts/${companyId}/active?page=${pageNumber}`,
            ),
        [get],
    );

    const getInActiveAccounts = useCallback(
        (companyId, pageNumber) =>
            get(
                `/core/customer_chart_of_accounts/${companyId}/inactive?page=${pageNumber}`,
            ),
        [get],
    );

    const updateAccount = (customerId, companyId, data) =>
        patch(
            `/core/customer-chart-of-accounts/${companyId}/update-note/${customerId}/`,
            data,
        );

    return {
        isLoading,
        getIndustries,
        updateAccount,
        getActiveAccounts,
        getInActiveAccounts,
        getTransactionStatusOptions,
        getCustomerChartsOfAccountsForEdit,
        getListOfParentsAccounts,
        getCoaAccount,
    };
};

import { Badge, Divider, Grid } from '@mui/material';
import { ReactComponent as CirclerIconDarkMode } from '../../assets/icons/CirclerIconDarkMode.svg';
import { ReactComponent as CirclerIcon } from '../../assets/icons/Icon.svg';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { Typography } from '../../shared';

const styles = {
    chatHeading: {
        color: (theme) => theme.textColor.dark,
        fontWeight: 600,
    },
};

export const ChatHeader = ({ isDarkMode }) => {
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    return (
        <Grid container spacing={1} width={'100%'}>
            <Grid
                item
                container
                xs={12}
                spacing={2}
                justifyContent={'space-between'}
            >
                <Grid
                    item
                    container
                    xs={isMobileScreen ? 12 : 6}
                    spacing={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Grid item>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                            color="success"
                            sx={{
                                '& .MuiBadge-dot': {
                                    backgroundColor: '#0CAF60',
                                },
                            }} // Setting custom color
                        >
                            {isDarkMode ? (
                                <CirclerIconDarkMode />
                            ) : (
                                <CirclerIcon />
                            )}
                        </Badge>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            text="Scan receipts"
                            customStyles={styles.chatHeading}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item sx={{ width: '100%', mt: 1 }}>
                <Divider flexItem />
            </Grid>
        </Grid>
    );
};

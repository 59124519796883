import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { FullWidthTabs, Typography } from '../shared';
import { PROFILE_SETTINGS } from '../utils/constants';
import { PersonalInformation } from './Settings/PersonalInformation';
import { CompanyInformation } from './Settings/CompanyInformation';
import Industry from './Settings/Industry';
import Security from './Settings/Security';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';

const makeStyles = ({ isScreenLowerThan1024 }) => ({
    root: {
        p: isScreenLowerThan1024 ? '0px' : '24px',
        height: 'calc(100vh - 69px)',
        justifyContent: isScreenLowerThan1024 ? 'flex-end' : 'space-between',
    },
    settingContainer: {
        displayed: 'flex',
        flexDirection: 'column',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    settingOptions: {
        display: 'flex',
        alignItems: 'center',
        p: '20px 16px',
        borderRadius: '12px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
        '& p': {
            fontSize: '14px',
        },
    },
    selectedOption: {
        backgroundColor: (theme) => theme.backgroundColor.profileSettings,
        '& .profileIcon': {
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#6C727F' : '#F9FAFB',
        },
        '& p': {
            fontSize: '14px',
            fontWeight: 700,
            color: (theme) => theme.textColor.dark,
        },
        '& path, & circle, & rect, & polygon, & line, & polyline, & ellipse': {
            transition: 'fill 0.3s, stroke 0.3s',
        },
        //&.selected rect
        '&.selected path, &.selected circle, &.selected polygon, &.selected line, &.selected polyline, &.selected ellipse':
            {
                stroke: (theme) =>
                    theme.palette.mode === 'dark'
                        ? theme.backgroundColor.light
                        : '',
            },
    },
});

const tabs = [
    { label: 'Personal Information', content: <PersonalInformation /> },
    {
        label: 'Company Information',
        content: (
            <CompanyInformation
                showIndustry={false}
                showCompanyHeader={true}
                companyLabel="Company Name"
                timezoneLabel={'Time Zone'}
                industryLabel={'Industry'}
                typeOfEntityLabel={'Type of Entity'}
            />
        ),
    },
    {
        label: 'Industry',
        content: <Industry />,
    },
    {
        label: 'Security',
        content: <Security />,
    },
];

export const Profile = () => {
    const { themeMode } = useThemeToggle();
    const [selectedProfileIndex, setSelectedProfileIndex] = useState(0);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const styles = makeStyles({ isScreenLowerThan1024 });

    const handleSelectedProfile = (chatId) => {
        setSelectedProfileIndex(chatId);
    };

    //TODO: Might need to memoize this function
    const renderSelectedUserSettings = (settingId) => {
        switch (settingId) {
            case 0:
                return <PersonalInformation />;
            case 1:
                return (
                    <CompanyInformation
                        showIndustry={false}
                        showCompanyHeader={true}
                        companyLabel="Company Name"
                        timezoneLabel={'Time Zone'}
                        industryLabel={'Industry'}
                        typeOfEntityLabel={'Type of Entity'}
                    />
                );
            case 2:
                return <Industry />;
            case 3:
                return <Security />;
            default:
                return <div>No component selected</div>;
        }
    };

    return (
        <Grid container spacing={2} sx={styles.root}>
            <Grid
                container
                item
                xs={12}
                md={isScreenLowerThan1024 ? 12 : 3}
                lg={isScreenLowerThan1024 ? 12 : 3}
                sx={styles.settingContainer}
                spacing={2}
                p={
                    isScreenLowerThan1024
                        ? '24px 0px 24px 14px'
                        : '24px !important'
                }
                overflow={'auto'}
            >
                {isScreenLowerThan1024 ? (
                    <Grid
                        item
                        xs={12}
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#121212'
                                    : '#F4F4F6',
                            p: 1,
                            m: '8px 0px 8px 8px',
                        }}
                    >
                        <FullWidthTabs
                            tabs={tabs}
                            variant={'scrollable'}
                            tabCustomStyles={{
                                fontSize: 14,
                                fontWeight: 600,
                                textTransform: 'capitalize',
                            }}
                        />
                    </Grid>
                ) : (
                    PROFILE_SETTINGS.map((setting, index) => {
                        return (
                            <Grid
                                item
                                key={index}
                                onClick={() => handleSelectedProfile(index)}
                                sx={{
                                    ...styles.settingOptions,
                                    ...(selectedProfileIndex === index &&
                                        styles.selectedOption),
                                }}
                                className={
                                    selectedProfileIndex === index
                                        ? 'selected'
                                        : ''
                                }
                            >
                                <Grid item>
                                    <Box
                                        className="profileIcon"
                                        sx={{
                                            borderRadius: '1000px',
                                            width: '48px',
                                            height: '48px',
                                            padding: '12px',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F9FAFB',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {themeMode === 'dark'
                                            ? setting.darkModeIcon
                                            : setting.icon}
                                    </Box>
                                </Grid>
                                &nbsp;&nbsp;
                                <Grid item>
                                    <Typography
                                        text={setting.name}
                                        customStyles={{
                                            color: (theme) =>
                                                theme.textColor.primary,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        );
                    })
                )}
            </Grid>

            {!isScreenLowerThan1024 ? (
                <Grid item xs={12} md={9} lg={9} p={'40px 60px !important'}>
                    {renderSelectedUserSettings(selectedProfileIndex)}
                </Grid>
            ) : (
                <></>
            )}
        </Grid>
    );
};

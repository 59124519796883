import { format } from 'date-fns';
import { isEmpty } from 'lodash';

// Shows the bytes in MB, KB or B
export const formatBytes = (bytes: string | number) => {
    const numericBytes = +bytes;
    if (numericBytes >= 1024 * 1024) {
        return `${(numericBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (numericBytes >= 1024) {
        return `${(numericBytes / 1024).toFixed(2)} KB`;
    } else {
        return `${numericBytes} Bytes`;
    }
};

// This function passes the component height to Filestack so
// it can return a lighter optimized version of the image
export const optimizeImg = (imageUrl: string, height: number) => {
    const filestackUrl = 'https://cdn.filestackcontent.com/';
    const isFilestackUrl = imageUrl.match(filestackUrl);
    if (isFilestackUrl) {
        const index = filestackUrl.length;
        // Add extra height to guarantee resolution quality
        const newHeight = height + 300;
        return `${imageUrl.substring(
            0,
            index,
        )}resize=height:${newHeight}/${imageUrl.substring(index)}`;
    } else {
        return imageUrl;
    }
};

//'MM-DD-YYYY'
export const formateDate = (date: string, dateFormat) => {
    return format(new Date(date), dateFormat);
};

export function formatDateTime(isoString) {
    const date = new Date(isoString);

    // Format the date part
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Format the time part
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;

    // Combine date and time
    return `${day}-${month}-${year} ${strTime}`;
}

export const isObjectEmpty = (data) => {
    return isEmpty(data);
};

export const randomRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// to always return type string event when s may be falsy other than empty-string
export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const getRandomColor = () => {
    const colors = ['#00C791', '#0061DB', '#8633FF', '#FF1F6A'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
};

export const formatValue = (value) => {
    const absValue = Math.abs(value);
    let formattedValue;

    if (absValue >= 1e9) {
        // Billion
        formattedValue = `$${(absValue / 1e9).toFixed(1)}B`;
    } else if (absValue >= 1e6) {
        // Million
        formattedValue = `$${(absValue / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1000) {
        // Thousand
        formattedValue = `$${(absValue / 1000).toFixed(1)}K`;
    } else {
        // Less than 1000
        formattedValue = `$${absValue}`;
    }

    return value < 0 ? `-${formattedValue}` : formattedValue;
};

export const calculateStepSize = (range, desiredTickCount) => {
    const roughStepSize = range / (desiredTickCount - 1);
    const magnitude = Math.floor(Math.log10(roughStepSize));
    const magnitudePower = Math.pow(10, magnitude);
    const roundedRoughStepSize =
        Math.ceil(roughStepSize / magnitudePower) * magnitudePower;
    return roundedRoughStepSize;
};

export const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const isTaxIncluded = (tax, taxed) => {
    const taxId = tax?.id;
    const taxedIds = taxed?.map((t) => t?.id);
    return taxedIds?.includes(taxId);
};

export const calculateTax = (services) => {
    let totalServicesAmount = 0;
    let fixedDollarTaxes = new Set();

    const allTaxes = services?.flatMap((service) => {
        totalServicesAmount += parseFloat(service?.total || 0);
        return service?.taxed?.map((tax) => {
            let calculatedAmount = 0;
            let taxPercent = null;

            if (tax?.unit === '%') {
                taxPercent = parseFloat(tax?.amount);
                calculatedAmount =
                    (taxPercent / 100) * parseFloat(service?.total);
            } else if (tax?.unit === '$') {
                taxPercent = null;
                if (!fixedDollarTaxes.has(tax?.name)) {
                    fixedDollarTaxes.add(tax?.name);
                    calculatedAmount = parseFloat(tax?.amount);
                } else {
                    calculatedAmount = 0; // Already added, so ignore this amount
                }
            }

            return {
                taxName: tax?.name,
                taxPercent,
                calculatedAmount,
            };
        });
    });

    let totalTax = 0;
    const aggregatedTaxes = allTaxes?.reduce((acc, detail) => {
        if (!detail.calculatedAmount) return acc; // Skip zero calculated amounts

        let existing = acc?.find(
            (item) =>
                item?.taxName === detail?.taxName &&
                item?.taxPercent === detail?.taxPercent,
        );

        if (existing) {
            existing.calculatedAmount += parseFloat(detail?.calculatedAmount);
        } else {
            acc.push({
                taxName: detail?.taxName,
                taxPercent: detail?.taxPercent,
                calculatedAmount: parseFloat(detail?.calculatedAmount),
            });
        }
        totalTax += parseFloat(detail?.calculatedAmount);
        return acc;
    }, []);

    aggregatedTaxes?.forEach(
        (tax) =>
            (tax.calculatedAmount = parseFloat(tax?.calculatedAmount).toFixed(
                2,
            )),
    );
    const totalAmount = totalServicesAmount + totalTax;

    return {
        taxArray: aggregatedTaxes,
        totalTax: totalTax?.toFixed(2),
        totalAmount: totalAmount?.toFixed(2),
        subTotal: totalServicesAmount.toFixed(2),
    };
};

export const getServicesFormatted = (singleInvoice) => {
    let newServices = [];
    let client = null;
    if (singleInvoice?.id) {
        const view = singleInvoice;
        const clientData = view?.client;

        newServices = view?.invoice_services?.map((invoiceService) => {
            const serviceData = invoiceService?.service;
            const total =
                (parseFloat(serviceData?.price) || 0) *
                (invoiceService?.quantity || 1);
            return {
                name: serviceData?.name || 'Type to add a service',
                qty: invoiceService?.quantity || 1,
                item: 'Item',
                serviceId: serviceData?.id,
                unitPrice: parseFloat(serviceData?.price) || 0,
                taxed: invoiceService?.taxed || [],
                total: total,
                details: serviceData?.details || 'Description ...',
            };
        });
        client = {
            id: clientData?.id || '',
            fullName: clientData?.id
                ? `${clientData?.full_name}\n${clientData?.email}\n${clientData?.phone_number}\n${clientData?.address_line_1} ${clientData?.city} ${clientData?.state} ${clientData?.zip_code}`
                : '',
        };
    }
    return { newServices, client };
};

export const changeServicesTax = (prevItems, isChecked, tax, index) => {
    const updatedItems = [...prevItems];
    const updatedItem = { ...updatedItems[index] };
    if (isChecked) {
        updatedItem.taxed = updatedItem.taxed
            ? [...updatedItem.taxed, tax]
            : [tax];
    } else {
        updatedItem.taxed = updatedItem.taxed.filter(
            (item) => item?.id !== tax?.id,
        );
    }
    updatedItems[index] = updatedItem;
    return updatedItems;
};

export const formatServices = (services) => {
    return services?.map((service) => ({
        quantity: service.qty,
        taxed: service?.taxed?.map((item) => item?.id),
        service: service?.id || service?.serviceId,
    }));
};

export const formatDateService = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getFormattedServices = (data) => {
    data?.map((service, index) => ({
        id: service?.id,
        service: service?.name,
        price: service?.price,
        details: service?.details,
        isNew: false,
        errors: { name: '', price: '', details: '' },
    }));
};

export const updateServicesWithTaxes = (services, taxes) => {
    const isDollarTax = (tax) => tax?.unit === '$';
    const validDollarTaxes = taxes?.filter(isDollarTax);
    const validDollarTaxIds = new Set(validDollarTaxes.map((tax) => tax?.id));

    const taxMap = new Map(taxes.map((tax) => [tax.id, tax]));

    const updatedServices = services?.map((service) => {
        const updatedService = { ...service };

        // Update taxed array
        updatedService.taxed = updatedService?.taxed
            ?.map((taxedItem) => {
                const matchedTax = taxMap.get(taxedItem.id);
                if (matchedTax) {
                    // If the tax unit is $ in taxes and % in taxed, change the unit in taxed
                    if (matchedTax.unit === '$' && taxedItem.unit === '%') {
                        return { ...taxedItem, unit: '$' };
                    }
                }
                return taxedItem;
            })
            .filter(
                (tax) => !isDollarTax(tax) || validDollarTaxIds?.has(tax?.id),
            );

        // Add valid dollar taxes to the service if they are not already present
        validDollarTaxes?.forEach((tax) => {
            if (!updatedService?.taxed?.some((t) => t?.id === tax?.id)) {
                updatedService?.taxed?.push(tax);
            }
        });

        return updatedService;
    });

    return updatedServices;
};

export const replaceServiceTaxes = (services, taxes) => {
    const taxMap = new Map(
        taxes?.filter((tax) => tax?.id)?.map((tax) => [tax?.id, tax]),
    );
    const updatedServices = services?.map((service) => {
        const updatedService = { ...service };

        updatedService.taxed = updatedService?.taxed?.map(
            (taxedItem) => taxMap?.get(taxedItem?.id) || taxedItem,
        );
        return updatedService;
    });

    return updatedServices;
};

export const convertToEpochInterval = (value, type) => {
    let milliseconds;

    switch (type) {
        case 'days':
            milliseconds = value * 24 * 60 * 60 * 1000;
            break;
        case 'weeks':
            milliseconds = value * 7 * 24 * 60 * 60 * 1000;
            break;
        case 'months':
            milliseconds = value * 30 * 24 * 60 * 60 * 1000;
            break;
        default:
            milliseconds = value * 24 * 60 * 60 * 1000;
    }

    return milliseconds;
};

export const convertFromMilliseconds = (milliseconds, type) => {
    let value;

    switch (type) {
        case 'days':
            value = milliseconds / (24 * 60 * 60 * 1000);
            break;
        case 'weeks':
            value = milliseconds / (7 * 24 * 60 * 60 * 1000);
            break;
        case 'months':
            value = milliseconds / (30 * 24 * 60 * 60 * 1000); // Approximation: average month duration
            break;
        default:
            value = milliseconds / (24 * 60 * 60 * 1000);
    }

    return value;
};

export const formatAccountTitleRow = (account) => {
    const formattedName = account?.account_name
        ?.split(' ')
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
    return account
        ? `${account?.account_number} ${formattedName} (${formatTitle(
              account?.account_type_3,
          )})`
        : 'Select Category';
};

export const formatTitle = (title) => {
    return title
        ?.split('_')
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
};

export const formatAccountTitle = (account) => {
    const formattedName = account?.account_name
        ?.split(' ')
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
        ?.join(' ');
    return !!formattedName
        ? `${account?.account_number}, ${formattedName}`
        : 'Select Category';
};

const rearrangeCategories = (categories) => {
    const expenseAndRevenue = [];
    const others = [];

    categories?.forEach((category) => {
        if (category?.title === 'Expense' || category?.title === 'Revenue') {
            expenseAndRevenue?.push(category);
        } else {
            others?.push(category);
        }
    });

    return [...expenseAndRevenue, ...others];
};

export const formatAccountCategoriesData = (data, isFirstTwo = false) => {
    const categories = data?.categories;
    const activeAccounts = data?.active_accounts;

    const transformCategory = (categoryName) => {
        return categories?.[categoryName]?.map((subCategory) => {
            const accounts = activeAccounts
                ?.filter(
                    (account) =>
                        account?.account_type_1?.trim()?.toLowerCase() ===
                            categoryName?.trim()?.toLowerCase() &&
                        account?.account_type_3?.trim()?.toLowerCase() ===
                            subCategory?.trim()?.toLowerCase(),
                )
                ?.map((account) => ({
                    title: formatAccountTitle(account),
                    accountNumber: account?.id,
                    isParent: !!account?.parent_account,
                }));

            return {
                title: formatTitle(subCategory),
                children: accounts?.length ? accounts : [{ title: 'No Data' }],
            };
        });
    };

    const transformedCategories = Object?.keys(categories)?.map(
        (categoryName) => ({
            title: formatTitle(categoryName),
            children: transformCategory(categoryName),
        }),
    );

    return {
        title: 'Select',
        children: isFirstTwo
            ? rearrangeCategories(transformedCategories)?.slice(0, 2)
            : rearrangeCategories(transformedCategories),
    };
};

export const removeTransactionsById = (data, idsToRemove) => {
    const result = {};
    for (const [account, transactions] of Object?.entries(data)) {
        result[account] = transactions?.filter(
            (transaction) => !idsToRemove?.includes(transaction?.id),
        );
    }
    return result;
};

export const getAccounts = (data) => {
    return Object?.keys(data)?.map((account) => account);
};

export const decrementAndFilterPages = (initialState) => {
    const updatedState = {};
    const accountIdsNew = [];
    const accountIdsComplete = [];
    let accountIdsCompleteKey = {};

    Object?.entries(initialState)
        ?.map(([key, value]) => [key, value - 1]) // Decrement each value by 1
        ?.filter(([_, value]) => value >= 1) // Keep only entries with values >= 1
        ?.forEach(([key, value]) => {
            updatedState[key] = value;
            // Extract the account ID part (assuming it's the part before the first underscore)
            const accountId = parseInt(key?.split('_')?.[0]);
            const accountCompleteId = key;
            accountIdsNew.push(accountId);
            accountIdsComplete.push(accountCompleteId);
            accountIdsCompleteKey = { ...accountIdsCompleteKey, [key]: 1 };
        });

    return {
        updatedState,
        accountIdsNew,
        accountIdsComplete,
        accountIdsCompleteKey,
    };
};

export const formatTransactionAccount = (inComingAccount) => {
    return {
        id: inComingAccount?.id,
        account_number: inComingAccount?.account_number,
        account_name: inComingAccount?.account_name,
        account_type_3: inComingAccount?.account_type_3,
    };
};
export const replaceTransactionAccountWithNewAccount = (
    inComingAccount,
    transactionsData,
    transactionId,
) => {
    const result = {};
    const newAccount = formatTransactionAccount(inComingAccount);
    for (const [account, transactions] of Object?.entries(transactionsData)) {
        result[account] = transactions?.map((transaction) =>
            transaction?.id === transactionId
                ? { ...transaction, coa_account: { ...newAccount } }
                : transaction,
        );
    }
    return result;
};

export const getSelectedTransactionIds = (data) => {
    let result = [];
    // eslint-disable-next-line no-unused-vars
    for (const [_, transactions] of Object?.entries(data)) {
        result = [
            ...result,
            ...transactions
                ?.filter((transaction) => transaction?.coa_account?.id)
                ?.map((transaction) => transaction?.id),
        ];
    }
    return result;
};

export const filterAccounts = (accounts, transactions) => {
    return accounts?.filter((account) =>
        transactions?.accounts_with_transactions?.hasOwnProperty(
            account?.account_id,
        ),
    );
};

export const filterAccountsSyncInitial = (accounts, transactions) => {
    return accounts?.filter((account) =>
        transactions?.accounts_with_added_transactions?.hasOwnProperty(
            account?.account_id,
        ),
    );
};

export const getCreateTransactionPayload = (
    transactionDate,
    description,
    account,
    amount,
    created_by,
    currency,
    companyId,
    paymentStatus,
) => {
    return {
        date: formatDateService(transactionDate),
        description,
        transaction_type: account?.account_type_1,
        transaction_total: amount,
        account: account?.id,
        is_paid: account?.id !== -1000,
        created_by,
        reviewed_at: null,
        currency,
        payment_method_account:
            paymentStatus?.id !== -1000 ? null : paymentStatus?.id,
        company: companyId,
    };
};

export const downloadJson = (data, filename) => {
    if (!data) {
        console.error('No data to download');
        return;
    }

    // Convert JSON data to a string
    const jsonString = JSON.stringify(data, null, 2);

    // Create a blob from the JSON string
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.json`); // Set the desired file name
    document.body.appendChild(link);
    link.click();

    // Clean up by revoking the object URL and removing the link element
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const processTransactionData = (data, formattedAccountTypes) => {
    const result = {};

    Object?.keys(formattedAccountTypes)?.forEach((key) => {
        const accountId = key?.split('_')?.[0];
        if (data?.accounts_with_added_transactions?.[accountId]) {
            result[key] =
                data?.accounts_with_added_transactions?.[
                    accountId
                ]?.plaid_transactions;
        }
    });

    return result;
};

export const processTransactionPages = (data, formattedAccountTypes) => {
    const result = {};

    Object?.keys(formattedAccountTypes)?.forEach((key) => {
        const accountId = key?.split('_')?.[0];
        if (data?.accounts_with_added_transactions?.[accountId]) {
            result[key] =
                data?.accounts_with_added_transactions?.[
                    accountId
                ]?.current_page;
        }
    });

    return result;
};

export const processTransactionTotalPages = (data, formattedAccountTypes) => {
    const result = {};

    Object?.keys(formattedAccountTypes)?.forEach((key) => {
        const accountId = key?.split('_')?.[0];
        if (data?.accounts_with_added_transactions?.[accountId]) {
            result[key] =
                data?.accounts_with_added_transactions?.[
                    accountId
                ]?.total_pages;
        }
    });

    return result;
};

export const formatCurrencyAmount = (amount) =>
    new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);

export const downloadPdf = (url, filename) => {
    if (!url) {
        console.error('No URL provided to download the PDF');
        return;
    }

    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${filename}.pdf`); // Set the download attribute with the filename
            document.body.appendChild(link); // Append the link to the body
            link.click(); // Trigger a click to start the download
            document.body.removeChild(link); // Remove the link from the document
            window.URL.revokeObjectURL(blobUrl); // Revoke the object URL to free memory
        })
        .catch((err) => console.error('Failed to download file:', err));
};

export const processPaymentStatus = function (status) {
    return status
        ?.split('_')
        ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        ?.join(' ');
};

export const updateTransactionAccount = (
    transactions,
    targetId,
    newAccount,
) => {
    return transactions?.map((transaction) => {
        if (transaction?.id === targetId) {
            transaction.account = {
                id: newAccount?.id,
                account_number: newAccount?.account_number,
                account_name: newAccount?.account_name,
            };

            transaction.journal_entries = transaction?.journal_entries?.map(
                (journalEntry) => {
                    if (
                        journalEntry?.account?.id === transaction?.account?.id
                    ) {
                        journalEntry.account = {
                            id: newAccount?.id,
                            account_number: newAccount?.account_number,
                            account_name: newAccount?.account_name,
                        };
                    }
                    return journalEntry;
                },
            );
        }
        return transaction;
    });
};

export const distributeTax = (dataIn, index) => {
    // Guard clauses to validate the input
    if (!Array.isArray(dataIn)) return [];
    if (typeof index !== 'number' || index < 0 || index >= dataIn?.length)
        return dataIn;

    // Copy the input data to avoid mutation
    const data = dataIn?.map((item) => ({ ...item }));

    // If the element at the given index is already marked as isThisTax, reset the array
    if (data?.[index]?.isThisTax) {
        return data?.map((item) => ({
            ...item,
            isThisTax: false,
            isTaxed: false,
            taxAmount: 0,
        }));
    }

    // Step 1: Ensure only the element at the given index is marked as the tax element,
    // set all others to isThisTax: false
    data?.forEach((item, i) => {
        item.isThisTax = i === index;
        item.taxAmount = 0; // Reset taxAmount for all
        item.isTaxed = false; // Reset isTaxed for all
    });

    const taxElement = data[index];
    const taxAmount = +taxElement?.price;

    // Step 2: Calculate the sum of the amounts of all elements except the tax element
    const totalAmountExcludingTax = data
        ?.filter((item, i) => i !== index) // Exclude the tax element
        ?.reduce((sum, item) => sum + (+item?.price || 0), 0);

    // If the total amount excluding tax is zero, return data without making changes
    if (totalAmountExcludingTax === 0) return data;

    // Step 3: Distribute the tax amount proportionally to each element
    const updatedData = data?.map((item, i) => {
        if (i !== index) {
            // Calculate the proportion of this element's amount with respect to the total
            const proportion = +item?.price / totalAmountExcludingTax;

            // Calculate the tax amount for this element
            const calculatedTax = proportion * taxAmount;

            return {
                ...item,
                isTaxed: true, // Mark as taxed
                taxAmount: parseFloat(calculatedTax?.toFixed(2)), // Set the calculated tax amount, fixed to 2 decimal places
            };
        } else {
            // Return the tax element with no changes to taxAmount
            return {
                ...item,
                taxAmount: 0, // The tax element itself has no taxAmount
            };
        }
    });

    return updatedData;
};

export const calculateTaxForElement = (dataIn, index) => {
    // Guard clauses to ensure valid input
    if (!Array.isArray(dataIn)) return [];
    if (typeof index !== 'number' || index < 0 || index >= dataIn?.length)
        return dataIn;

    // Copy the input data to avoid mutation
    const data = dataIn?.map((item) => ({ ...item }));

    // Check if the element at the index is already taxed
    if (data?.[index]?.isTaxed === true) {
        // Reset the element if it's already taxed
        data[index].isTaxed = false;
        data[index].taxAmount = 0;
        return data;
    }

    // Find the element that has isThisTax: true
    const taxElement = data?.find((item) => item?.isThisTax === true);

    if (!taxElement || !taxElement?.price) {
        // If no tax element or tax amount is found, return the original data
        return data;
    }

    // Calculate the total amount of all elements except the tax element
    const totalAmountExcludingTax = data
        ?.filter((item) => item !== taxElement)
        ?.reduce((sum, item) => sum + (+item?.price || 0), 0);

    if (totalAmountExcludingTax === 0) {
        // If there's nothing to distribute tax to, return the original data
        return data;
    }

    // Calculate the tax amount based on the proportion of the index element's amount
    const taxAmount =
        (+data?.[index]?.price / totalAmountExcludingTax) * +taxElement?.price;

    // Update the index element with the calculated tax amount
    data[index].isTaxed = true;
    data[index].taxAmount = parseFloat(taxAmount?.toFixed(2)); // Fix to 2 decimal places

    return data;
};

export const getThisTaxElementIndex = (data) => {
    // Check if data is an array, if not return null
    if (!Array.isArray(data)) return null;

    // Find the index of the element with isThisTax: true
    const index = data?.findIndex((item) => item?.isThisTax === true);

    // If no element is found, return null, otherwise return the index
    return index === -1 ? null : index;
};

export const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Remove "data:*/*;base64," prefix
        reader.onerror = (error) => reject(error);
    });
};

export const validateTransaction = (data) => {
    // Check if 'book_by_each_item' is false
    if (!data?.book_by_each_item) {
        if (!data?.account || !data?.account?.id) {
            return 'Account Category is required to import the transaction';
        }
        // if (parseFloat(data?.transaction_total || 0) < 0) {
        //     return 'Transaction Amount must be a positive integer';
        // }
    } else {
        // If 'book_by_each_item' is true, validate each 'transaction_items' object
        for (const item of data?.transaction_items) {
            if (!item?.account || !item?.account?.id) {
                return 'Account Category is required in transaction items';
            }
            // if (parseFloat(item?.item_total || 0) < 0) {
            //     return 'Transaction Item Amount must be a positive integer';
            // }
        }
        const totalItemsSum = data?.transaction_items?.reduce(
            (sum, item) =>
                item?.isThisTax
                    ? sum + parseFloat(item?.taxAmount || 0)
                    : sum +
                      parseFloat(item?.item_total || 0) +
                      parseFloat(item?.taxAmount || 0),
            0,
        );
        if (parseFloat(data?.transaction_total) !== totalItemsSum) {
            return 'Transaction Items Total does not agree with the Amount';
        }
    }

    // If all validations pass, return null
    return null;
};

export const toggleIsThisTax = (dataIn, index, isThisTax) => {
    if (!Array.isArray(dataIn)) return [];
    if (typeof index !== 'number' || index < 0 || index >= dataIn.length)
        return dataIn;

    const data = dataIn.map((item, i) => {
        // Set isThisTax and isTaxed based on the isThisTax parameter for the specific index
        if (i === index) {
            return {
                ...item,
                isThisTax: isThisTax,
                isTaxed: !isThisTax, // Set isTaxed to false if isThisTax is true
                taxAmount: 0,
            };
        }
        // For all other elements, toggle isTaxed based on isThisTax
        return {
            ...item,
            isThisTax: false,
            isTaxed: isThisTax, // Set isTaxed to true if isThisTax is true
            taxAmount: 0,
        };
    });

    return data;
};

export const toggleIsTaxed = (dataIn, index, isTaxed) => {
    if (!Array.isArray(dataIn)) return [];
    if (typeof index !== 'number' || index < 0 || index >= dataIn.length)
        return dataIn;

    const data = dataIn.map((item) => ({ ...item }));

    // Set isTaxed based on the provided boolean value
    data[index].isTaxed = isTaxed;

    return data;
};

export const distributeTaxAmongTaxed = (dataIn) => {
    if (!Array.isArray(dataIn)) return [];

    const data = dataIn.map((item) => ({ ...item }));
    const taxElement = data.find((item) => item.isThisTax);

    // If there's no element marked as `isThisTax`, return the data without changes
    if (!taxElement) return data;

    const taxAmount = Math.abs(+taxElement?.item_total || 0);

    // Calculate the sum of the item_total of all elements marked as `isTaxed`
    const totalAmountTaxed = data
        .filter((item) => item.isTaxed)
        .reduce((sum, item) => sum + Math.abs(+item?.item_total || 0), 0);

    // If the total amount of taxed items is zero, return data without making changes
    if (totalAmountTaxed === 0)
        return data.map((item) => ({
            ...item,
            taxAmount: 0,
            isThisTax: false,
            isTaxed: false,
        }));

    // Distribute the tax amount proportionally to each element marked as `isTaxed`
    return data.map((item) => {
        if (item.isTaxed) {
            const proportion =
                Math.abs(+item.item_total || 0) / totalAmountTaxed;
            const calculatedTax = proportion * taxAmount;
            return {
                ...item,
                taxAmount: parseFloat(calculatedTax.toFixed(2)),
            };
        }
        return { ...item, taxAmount: 0 };
    });
};

export const ACCOUNT_TYPES_1 = [
    {
        value: 'asset',
        label: 'Asset',
    },
    {
        value: 'liability',
        label: 'Liability',
    },
    {
        value: 'expense',
        label: 'Expense',
    },
    {
        value: 'equity',
        label: 'Equity',
    },
    {
        value: 'revenue',
        label: 'Revenue',
    },
    {
        value: 'gain_loss',
        label: 'Gane Loss',
    },
];

export const ACCOUNT_TYPES_2 = {
    asset: [
        {
            value: 'current',
            label: 'Current',
        },
        {
            value: 'non-current',
            label: 'Non Current',
        },
    ],
    liability: [
        {
            value: 'current',
            label: 'Current',
        },
        {
            value: 'non-current',
            label: 'Non Current',
        },
    ],
    expense: [
        {
            value: 'cost_of_revenue',
            label: 'Cost Of Revenue',
        },
        {
            value: 'operating',
            label: 'Operating',
        },
        {
            value: 'non-operating',
            label: 'Non Operating',
        },
        {
            value: 'taxes',
            label: 'Taxes',
        },
    ],
    equity: [
        {
            value: 'equity',
            label: 'Equity',
        },
    ],
    revenue: [
        {
            value: 'revenue',
            label: 'Revenue',
        },
    ],
    gain_loss: [
        {
            value: 'non-operating',
            label: 'Non Operating',
        },
    ],
};

import { Menu as MuiMenu, MenuItem, Grid, Theme, Box } from '@mui/material';
import Image from 'mui-image';
import CircularIndeterminate from './CircularLoader';

const styles = {
    paper: {
        mt: 1,
        borderRadius: '12px',
        '& ul': {
            p: 0,
            m: 0,
            width: '100%',
            'li:hover': {
                borderRadius: '12px',
            },
        },
        display: 'flex',
        p: '12px',
        alignItems: 'center',
        flexShrink: 0,
        boxShadow: (theme) => theme.boxShadow,
        background: (theme) => theme.backgroundColor.primary,
        border: (theme) => `1px solid ${theme.borderColor.inputField}`,
    },
    menuItem: {
        fontSize: '14px',
        fontWeight: 500,
        p: '16px',
        m: '5px 0',
        width: '100%',
        borderColor: (theme: Theme) => theme.borderColor.inputField,
    },
};

export const Menu = ({
    anchorEl,
    items,
    handleClose,
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
    customStyles,
    isLoading = false,
}) => {
    return (
        <MuiMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
            PaperProps={{ sx: { ...styles.paper, ...customStyles } }}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            {isLoading ? (
                <Box sx={styles.menuItem}>
                    <CircularIndeterminate />
                </Box>
            ) : (
                items.map((item: any, index: number) => (
                    <MenuItem
                        key={index}
                        onClick={(e) => {
                            item?.onClick && handleClose(e);
                            item?.onClick && item.onClick(e);
                        }}
                        divider={!!item.showDivider}
                        sx={{
                            ...styles.menuItem,
                            ...(!item?.onClick && {
                                '&:hover': {
                                    backgroundColor: 'unset !important',
                                },
                            }),
                        }}
                    >
                        {item?.component ? (
                            <item.component />
                        ) : (
                            <Grid container spacing={2} width={'100%'}>
                                {item?.icon && (
                                    <Grid item>
                                        <Image
                                            src={item?.icon}
                                            alt={item.name}
                                        />
                                    </Grid>
                                )}
                                <Grid item container>
                                    {item.name}
                                </Grid>
                            </Grid>
                        )}
                    </MenuItem>
                ))
            )}
        </MuiMenu>
    );
};

import { Box, Grid } from '@mui/material';
import { DataTableScanReceipt } from 'shared/DataTableScanReceipt';
import { TablePagination } from '../shared';

export const Reviews = ({
    reviewsRef,
    transactions,
    page,
    count,
    handlePageChange,
    isMobileScreen,
    themeMode,
    onEditClick,
    collapse,
    editId,
    handleEditClick,
    handleCollapse,
    onSave,
    description,
    handleDescriptionChange,
    transactionDate,
    setTransactionDate,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    amount,
    handleAmountChange,
    errors,
    isSubmitLoading,
    handleVendorChange,
    vendor,
    handlePaymentDropdownOpen,
    paymentStatuses,
    isPaymentLoading,
    panelData,
    onListItemClick,
    changeDropDownLength,
    isPanelDataLoading,
    openDropDownIndex,
    handleDropDownToggle,
    handleDropDownClose,
    handleEditClickSecondTable,
    hanldeCloseEditSecondTable,
    editIdSecondTable,
    submitSecondaryTableRow,
    setSecondaryRows,
    isLoading,
    importPostTransaction,
    handleRemoveTransaction,
    addTransactionItem,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    handleAddTransactionItem,
    handleSourceModalOpen,
    isEdit,
}) => {
    return (
        <Grid item width={'100%'} ref={reviewsRef}>
            <Box>
                <Grid item xs={12} container mt={2}>
                    <Grid item xs={12}>
                        <DataTableScanReceipt
                            isEdit={isEdit}
                            isMobileScreen={isMobileScreen}
                            actionIconPlacement={'top'}
                            data={transactions}
                            isLoading={isLoading}
                            themeMode={themeMode}
                            onEditClick={onEditClick}
                            collapse={collapse}
                            handleCollapse={handleCollapse}
                            editId={editId}
                            handleEditClick={handleEditClick}
                            onSave={onSave}
                            description={description}
                            handleDescriptionChange={handleDescriptionChange}
                            transactionDate={transactionDate}
                            setTransactionDate={setTransactionDate}
                            selectedPaymentStatus={selectedPaymentStatus}
                            handlePaymentStatusChange={
                                handlePaymentStatusChange
                            }
                            amount={amount}
                            handleAmountChange={handleAmountChange}
                            errors={errors}
                            isSubmitLoading={isSubmitLoading}
                            handleVendorChange={handleVendorChange}
                            vendor={vendor}
                            handlePaymentDropdownOpen={
                                handlePaymentDropdownOpen
                            }
                            paymentStatuses={paymentStatuses}
                            isPaymentLoading={isPaymentLoading}
                            panelData={panelData}
                            onListItemClick={onListItemClick}
                            changeDropDownLength={changeDropDownLength}
                            isPanelDataLoading={isPanelDataLoading}
                            openDropDownIndex={openDropDownIndex}
                            handleDropDownToggle={handleDropDownToggle}
                            handleDropDownClose={handleDropDownClose}
                            handleEditClickSecondTable={
                                handleEditClickSecondTable
                            }
                            hanldeCloseEditSecondTable={
                                hanldeCloseEditSecondTable
                            }
                            editIdSecondTable={editIdSecondTable}
                            submitSecondaryTableRow={submitSecondaryTableRow}
                            setSecondaryRows={setSecondaryRows}
                            importPostTransaction={importPostTransaction}
                            handleRemoveTransaction={handleRemoveTransaction}
                            addTransactionItem={addTransactionItem}
                            secondTableTransactionDetails={
                                secondTableTransactionDetails
                            }
                            secondTableAmount={secondTableAmount}
                            secondTableQuantity={secondTableQuantity}
                            secondTableCategory={secondTableCategory}
                            errorsSecondTable={errorsSecondTable}
                            handleSecondDescriptionChange={
                                handleSecondDescriptionChange
                            }
                            handleSecondAmountChange={handleSecondAmountChange}
                            handleSecondQuantityChange={
                                handleSecondQuantityChange
                            }
                            handleRemoveTransactionItem={
                                handleRemoveTransactionItem
                            }
                            handleAddTransactionItem={handleAddTransactionItem}
                            handleSourceModalOpen={handleSourceModalOpen}
                        />
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            page={page}
                            count={count}
                            onChange={handlePageChange}
                            isMobileScreen={isMobileScreen}
                        />
                    </Grid>
                    {/* <TransactionAccordion/> */}
                </Grid>
            </Box>
        </Grid>
    );
};

import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import FileThumbnail, { fileData } from 'components/file-thumbnail';
import { fData } from 'utils/formatNumber';

export default function MultiFilePreview({ files, disabled, onRemove, sx }) {
    if (!files?.length) {
        return null;
    }

    return (
        <Grid container spacing={1}>
            {files?.map((file) => {
                const { key, name = '', size = 0 } = fileData(file);
                const isNotFormatFile = typeof file === 'string';
                return (
                    <Grid item xs={12} md={3}>
                        <Stack
                            key={key}
                            spacing={2}
                            direction="row"
                            alignItems="center"
                            sx={{
                                my: 1,
                                px: 1,
                                py: 0.75,
                                borderRadius: 0.75,
                                border: (theme) =>
                                    `solid 1px ${theme.palette.divider}`,
                                ...sx,
                            }}
                        >
                            <FileThumbnail file={file} />

                            <Stack flexGrow={1} sx={{ minWidth: 0 }}>
                                <Typography variant="subtitle2" noWrap>
                                    {isNotFormatFile ? file : name}
                                </Typography>

                                <Typography
                                    variant="caption"
                                    sx={{ color: 'text.secondary' }}
                                >
                                    {isNotFormatFile ? '' : fData(size)}
                                </Typography>
                            </Stack>

                            {onRemove && !!!disabled && (
                                <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={() => onRemove(file)}
                                >
                                    <CloseIcon style={{ fontSize: 16 }} />
                                </IconButton>
                            )}
                        </Stack>
                    </Grid>
                );
            })}
        </Grid>
    );
}

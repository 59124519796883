import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import Sidebar from '../shared/Sidebar';

export const SwipeableFromLeftDrawer = ({
    setOpen,
    isDarkMode,
    activeMenu,
    isDrawerOpen,
    currentPath,
    handleMenuClick,
    notificationStyle,
    isScreenLowerThan1024,
}) => {
    const toggleDrawer = () => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(!isDrawerOpen);
    };

    const list = (anchor) => {
        return (
            <Box
                sx={{
                    width: 250,
                }}
                role="presentation"
                onClick={toggleDrawer(anchor, true)}
                onKeyDown={toggleDrawer(anchor, true)}
            >
                <Divider />
                <Sidebar
                    isDrawerOpen={isDrawerOpen}
                    isDarkMode={isDarkMode}
                    activeMenu={activeMenu}
                    currentPath={currentPath}
                    setIsDrawerOpen={setOpen}
                    handleMenuClick={handleMenuClick}
                    notificationStyle={notificationStyle}
                    isScreenLowerThan1024={isScreenLowerThan1024}
                />
            </Box>
        );
    };

    return (
        <SwipeableDrawer
            sx={{
                '& .MuiPaper-root': {
                    backgroundImage: 'unset',
                },
            }}
            anchor={'left'}
            open={isDrawerOpen}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
        >
            {list('left')}
        </SwipeableDrawer>
    );
};

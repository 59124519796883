import React from 'react';
import { BarChart } from '../shared';
import { calculateStepSize, formatValue } from '../utils/helpers';

const arePropsEqual = (prevProps, nextProps) => {
    // Your custom prop comparison logic here
    return (
        prevProps.isDarkMode === nextProps.isDarkMode &&
        prevProps.data.datasets.length === nextProps.data.datasets.length
        // Add other relevant prop comparisons
        // Example: prevProps.data === nextProps.data
    );
};

const transformData = (data) => {
    const labels = data.labels;
    const revenue = data.datasets.map((entry) => entry.data[0]);
    const cost = data.datasets.map((entry) => entry.data[1]);

    return {
        labels: labels,
        datasets: [
            {
                label: 'Revenue',
                data: revenue,
                backgroundColor: '#0061DB',
                barThickness: 12,
                maxBarThickness: 12,
            },
            {
                label: 'Cost of Revenue',
                data: cost,
                backgroundColor: '#6DCFA0',
                barThickness: 12,
                maxBarThickness: 12,
            },

            // {
            //     label: 'Dataset 3',
            //     data: [data[2]],
            //     backgroundColor: '#8633FF',
            //     barThickness: 12,
            //     maxBarThickness: 12,
            // }
        ],
    };
};

const RevenueMonthChart = ({ data, isDarkMode }) => {
    // Calculate dynamic stepSize, max, and min based on data
    const maxData = Math.max(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );
    const minData = Math.min(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );
    const range = maxData - minData;
    const stepSize = calculateStepSize(range, 5);

    const revenueOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Bar Charts',
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#9EA3AE',
                },
            },
            y: {
                grid: {
                    color: isDarkMode ? '#6C727F' : '#E5E6EB', // X-axis grid color
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return formatValue(value);
                    },
                    crossAlign: 'far',
                    color: '#9EA3AE',
                    stepSize: stepSize,
                    max: maxData + stepSize, // Set the upper limit
                    min: minData - stepSize, // Set the lower limit
                },
            },
        },
    };

    const transformedData = transformData(data);
    return <BarChart options={revenueOptions} data={transformedData} />;
};

export default React.memo(RevenueMonthChart, arePropsEqual);

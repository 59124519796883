import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from '@emotion/styled';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from './Button';
import { Typography } from './Typography';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '12px',
        boxShadow: '10px 15px 50px 0px rgba(113, 128, 150, 0.24)',
        maxWidth: '700px',
        backgroundColor: theme.backgroundColor.primary,
        backgroundImage: 'unset',
    },
    backdropFilter: 'blur(2px)',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(3),
    },
}));

const styles = {
    cancelBtn: {
        color: (theme: Theme) => theme.textColor.primary,
        background: (theme: Theme) => theme.backgroundColor.primary,
    },
};

function BootstrapDialogTitle(props) {
    const { children, onClose, loading, ...other } = props;

    return (
        <DialogTitle
            color={(theme) => theme.textColor.dark}
            fontSize={'24px'}
            lineHeight="28.8px"
            fontWeight="600"
            textTransform={'capitalize'}
            sx={{
                m: 0,
                p: { xs: 4, sm: 4, lg: 4 },
                fontSize: { xs: '18px', sm: '24px' },
            }}
            {...other}
        >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: { xs: 2, sm: 30 },
                        color: '#9EA3AE',
                    }}
                    disabled={loading}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

export const Modal = (props) => {
    const {
        open,
        handleClose,
        title,
        children,
        actionBtnTitle,
        actionHandler,
        saveButtonStyle,
        loading,
        fullScreen = false,
        disabledBtn,
        fullWidth = false,
        showImprovementText = false,
        cancelBtnText = 'Cancel',
        customCancelBtnStyle,
        improvementText = '',
        dividers = false,
        showFooter,
        maxWidth = 'md',
    } = props;
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');

    return (
        <React.Fragment>
            <BootstrapDialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullScreen={fullScreen}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                aria-describedby="dialog-content"
                sx={{
                    '& .MuiModal-root .MuiDialog-root': {
                        zIndex: 10000,
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    loading={loading}
                >
                    {title}
                </BootstrapDialogTitle>
                <DialogContent dividers={dividers} sx={{ p: 4 }}>
                    {children}
                </DialogContent>
                <DialogActions sx={{ p: '24px 32px !important' }}>
                    {showFooter && (
                        <Grid container justifyContent={'space-between'}>
                            {showImprovementText && (
                                <Grid
                                    item
                                    xs={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <Typography
                                        text={improvementText}
                                        customStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.primary.dark,
                                            cursor: 'pointer',
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                spacing={1}
                                xs={showImprovementText ? 6 : 12}
                                justifyContent={'flex-end'}
                            >
                                <Grid
                                    item
                                    xs={
                                        isMobileScreen
                                            ? 6
                                            : actionHandler
                                            ? 6
                                            : 12
                                    }
                                    width={
                                        showImprovementText ? 'auto' : '100%'
                                    }
                                >
                                    <Button
                                        fullWidth={true}
                                        variant="outlined"
                                        text={
                                            <Typography
                                                text={cancelBtnText}
                                                customStyles={{
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        }
                                        disabled={loading}
                                        onClick={handleClose}
                                        customStyles={{
                                            ...styles.cancelBtn,
                                            ...customCancelBtnStyle,
                                        }}
                                    />
                                </Grid>
                                {actionHandler && (
                                    <Grid
                                        item
                                        xs={
                                            isMobileScreen
                                                ? 6
                                                : actionHandler
                                                ? 6
                                                : 2
                                        }
                                    >
                                        <Button
                                            loading={loading}
                                            text={actionBtnTitle}
                                            onClick={actionHandler}
                                            customStyles={{
                                                ...saveButtonStyle,
                                            }}
                                            disabled={loading || disabledBtn}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
};

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack } from '@mui/material';

export const ChatInput = ({
    files,
    handleRemoveAllFiles,
    handleUpload,
    isUploadLoading,
}) => {
    return (
        <Grid container mt={2}>
            <Grid item xs={12}>
                <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={2}
                >
                    {!!files?.length && (
                        <Button
                            disabled={isUploadLoading}
                            onClick={handleRemoveAllFiles}
                            sx={{
                                border: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#9EA3AE'
                                        : '#6C727F',
                                borderRadius: '8px',
                                outline: 'none',
                                textTransform: 'none',
                            }}
                        >
                            Remove all
                        </Button>
                    )}

                    <LoadingButton
                        disabled={!files?.length}
                        loading={isUploadLoading}
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={() => handleUpload(files)}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px',

                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                    >
                        Upload
                    </LoadingButton>
                </Stack>
            </Grid>
        </Grid>
    );
};

import { useEffect, useState } from 'react';
import { TIMEZONES } from '../utils/data';

const useTimezoneList = () => {
    const [timezoneList, setTimezoneList] = useState([]);

    useEffect(() => {
        const fetchTimezoneList = async () => {
            setTimezoneList(
                TIMEZONES.map((timezone) => {
                    return {
                        label: timezone.label.replace('_', ' '),
                        value: timezone.value,
                    };
                }),
            );
        };

        fetchTimezoneList();
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return timezoneList;
};

export default useTimezoneList;

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { SET_SELECTED_ITEM, syncTransactionsRequest } from 'store/actions';
import { useDispatch } from 'react-redux';
import { store } from 'store';
import { useNavigate } from 'react-router-dom';

const ToolbarTransaction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedAccount, setSelectedAccount] = React.useState('');
    const [selectedDateRange, setSelectedDateRange] = React.useState('');
    const [selectedExport, setSelectedExport] = React.useState('');
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const accountOptions = [
        { value: 'acc1', label: 'Account 1' },
        { value: 'acc2', label: 'Account 2' },
        { value: 'acc3', label: 'Account 3' },
        // Add more options as needed
    ];

    const dateRangeOptions = [
        { value: 'last7days', label: 'Last 7 Days' },
        { value: 'last30days', label: 'Last 30 Days' },
        { value: 'last6months', label: 'Last 6 Months' },
        // Add more options as needed
    ];

    const exportOptions = [
        { value: 'pdf', label: 'PDF' },
        { value: 'csv', label: 'CSV' },
        { value: 'excel', label: 'Excel' },
        // Add more options as needed
    ];

    const handleChange = (event, setter) => {
        setter(event.target.value);
    };

    const fetchTransactionData = async () => {
        dispatch(SET_SELECTED_ITEM('Import transactions'));
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                px: { xs: '10px', sm: '43px' },
                pt: '20px',
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', sm: 'auto' },
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    marginBottom: { xs: '20px', sm: 0 },
                }}
            >
                {/* <FormControl
                    sx={{
                        m: 1,
                        minWidth: 160,
                        width: '100%',
                        bgcolor:
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        borderRadius: '6px',
                    }}
                    size="small"
                >
                    <InputLabel id="date-range-label">Date Range</InputLabel>
                    <Select
                        labelId="date-range-label"
                        id="date-range-select"
                        value={selectedDateRange}
                        label="Date Range"
                        onChange={(e) => handleChange(e, setSelectedDateRange)}
                        sx={{
                            bgcolor:
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : '#FFFFFF',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border:
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                borderRadius: '6px',
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #E5E6EB',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    borderRadius: '8px',
                                },
                            },
                        }}
                    >
                        {dateRangeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                {/* <FormControl
                    sx={{
                        m: 1,
                        minWidth: 160,
                        width: '100%',
                        bgcolor:
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        borderRadius: '6px',
                    }}
                    size="small"
                >
                    <InputLabel id="list-account-label">
                        List of Account
                    </InputLabel>
                    <Select
                        labelId="list-account-label"
                        id="list-account-select"
                        value={selectedAccount}
                        label="List of Account"
                        onChange={(e) => handleChange(e, setSelectedAccount)}
                        sx={{
                            bgcolor:
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : '#FFFFFF',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border:
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                borderRadius: '6px',
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #E5E6EB',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    borderRadius: '8px',
                                },
                            },
                        }}
                    >
                        {accountOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}
            </Box>

            <Box
                sx={{
                    width: { xs: '100%', sm: 'auto' },
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'space-between', sm: 'flex-end' },
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                {/* <FormControl
                    sx={{
                        minWidth: 120,
                        bgcolor:
                            theme.palette.mode === 'dark' ? '#212936' : 'white',
                        borderRadius: '6px',
                    }}
                    size="small"
                >
                    <InputLabel id="export-label">Export</InputLabel>
                    <Select
                        labelId="export-label"
                        id="export-select"
                        value={selectedExport}
                        label="Export"
                        onChange={(e) => handleChange(e, setSelectedExport)}
                        sx={{
                            bgcolor:
                                theme.palette.mode === 'dark'
                                    ? '#212936'
                                    : '#FFFFFF',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border:
                                    theme.palette.mode === 'dark'
                                        ? '1px solid #394150'
                                        : '1px solid #E5E6EB',
                                borderRadius: '6px',
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #E5E6EB',
                            },
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    borderRadius: '8px',
                                },
                            },
                        }}
                    >
                        {exportOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl> */}

                {/* <Button
                    onClick={() => navigate('/home')}
                    sx={{
                        background: (theme) =>
                            theme.palette.mode === 'dark' ? '#212936' : '#fff',

                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#9EA3AE'
                                : '#6C727F',
                        borderRadius: '8px',
                        outline: 'none',
                        textTransform: 'none',
                        width: { xs: '100%', sm: 'auto' },
                    }}
                >
                    <Typography variant="subtitle">Scan Receipt</Typography>
                </Button> */}

                <Button
                    variant="contained"
                    startIcon={<SyncIcon />}
                    fullWidth={isSmallScreen}
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        textTransform: 'none',
                        borderRadius: '6px',
                        paddingX: '20px',
                        '&:hover': {
                            backgroundColor: '#0056b3',
                            '@media (hover: hover)': {
                                backgroundColor: '#0056b3',
                            },
                        },
                    }}
                    onClick={fetchTransactionData}
                >
                    Sync to Bank
                </Button>
            </Box>
        </Box>
    );
};

export default ToolbarTransaction;

import React, { useState } from 'react';
import {
    TextField as MuiTextField,
    InputLabel,
    CircularProgress,
    Popover,
    Box,
} from '@mui/material';
import { Typography } from './Typography';

const makeStyles = ({ isSearchField }) => ({
    label: {
        display: 'flex',
        fontSize: 13,
        fontWeight: 600,
        paddingBottom: 1,
        color: 'text.primary',
        lineHeight: '15.6px',
    },
    labelIcon: {
        pb: 0,
        marginLeft: 2,
        cursor: 'pointer',
    },
    textField: {
        '& .MuiInputBase-root': {
            '& input': {
                fontSize: 13,
                padding: '16px 14px',
                color: (theme) => theme.textColor.primary,
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },

            backgroundColor: (theme) =>
                isSearchField
                    ? theme.primary.light
                    : theme.palette.mode === 'dark'
                    ? '#394150'
                    : theme.backgroundColor.secondary,
            '& textarea': {
                '&::placeholder': {
                    color: (theme) => theme.textColor.primary,
                    opacity: 1,
                    fontSize: 13,
                },
            },
            '&:hover fieldset': {
                borderColor: '#6C727F',
                border: '1px solid #E5E6EB',
            },
            borderRadius: 2,
            '& fieldset': {
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#394150' : '#F4F4F6',
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            fontWeight: 600,
            ml: 0,
            textTransform: 'initial',
        },
    },
    popoverContainer: {
        p: '12px',
    },
});

export const TextField = ({
    label = '',
    type = 'text',
    loading = false,
    size = 'medium',
    placeholder = '',
    customStyle = {},
    variant = 'outlined',
    required = false,
    propagation = false,
    disabled = false,
    passwordVisible = true,
    isSearchField = false,
    value,
    onChange,
    name,
    labelIcon,
    labelIconText,
    customLabelStyles,
    multiline = false,
    minRows,
    ...props
}) => {
    const styles = makeStyles({ isSearchField });
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (
        <>
            {label && (
                <InputLabel sx={{ ...styles.label, ...customLabelStyles }}>
                    {`${label} ${required ? '* ' : ' '}`}
                    {loading && (
                        <CircularProgress
                            sx={{
                                width: '14px !important',
                                height: '14px !important',
                            }}
                        />
                    )}
                    {labelIcon && (
                        <Box sx={{ borderRadius: '8px' }}>
                            <InputLabel
                                sx={styles.labelIcon}
                                onMouseEnter={handlePopoverOpen}
                            >
                                {labelIcon}
                            </InputLabel>
                        </Box>
                    )}
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            '& .MuiPopover-paper': {
                                borderRadius: '8px',
                                backgroundColor: (theme) =>
                                    theme.primary.secondary,
                            },
                        }}
                    >
                        <Box sx={styles.popoverContainer}>
                            <Typography
                                text={labelIconText}
                                customStyles={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: (theme) => theme.primary.dark,
                                    maxWidth: '200px',
                                }}
                            />
                        </Box>
                    </Popover>
                </InputLabel>
            )}

            <MuiTextField
                fullWidth
                variant={variant}
                name={name}
                value={value}
                multiline={multiline}
                sx={{ ...styles.textField, ...customStyle }}
                type={passwordVisible ? 'text' : 'password'}
                size={size}
                onChange={onChange}
                placeholder={placeholder}
                onKeyDown={
                    propagation
                        ? (e) => {
                              e.stopPropagation();
                          }
                        : undefined
                }
                disabled={disabled}
                minRows={minRows}
                onClick={(e) => e.stopPropagation()}
                {...props}
            />
        </>
    );
};

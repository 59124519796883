import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid } from '@mui/material';
import { Button, Typography } from '../../shared';
import { LOGOUT } from '../../store/actions';
import { useAuth } from '../../hooks/useAuth';
import { useAuthService } from '../../store/services/auth.service';

const styles = {
    security: {
        p: '32px',
        borderRadius: '12px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    mainHeading: {
        fontSize: '24px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },
};

function Security() {
    const { logout } = useAuth();
    const { data } = useSelector((state) => state.me);
    const dispatch = useDispatch();
    const { resetPassword } = useAuthService();
    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <Grid
            item
            container
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles.security}
        >
            <Grid item>
                <Typography
                    text={'Password & Security'}
                    customStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                    }}
                />
                <Typography
                    text={'Change or update password.'}
                    customStyles={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: (theme) => theme.primary.dark,
                    }}
                />
            </Grid>
            <Grid item></Grid>
            <Grid item xs={12}>
                <Box width={'100%'}>
                    <Divider />
                </Box>
            </Grid>
            {showConfirmation ? (
                <Grid
                    container
                    item
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'end'}
                >
                    <Grid item>
                        <Typography
                            sx={styles.mainHeading}
                            text={'Check your email'}
                        />

                        <Typography
                            sx={styles.subHeading}
                            text={
                                'Password reset email has been sent your email address.'
                            }
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            text={
                                <Typography
                                    sx={{
                                        color: '#FFF',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                    }}
                                    text={'Logout'}
                                />
                            }
                            fullWidth
                            customStyles={{ height: '40px' }}
                            onClick={() => {
                                logout();
                                dispatch(LOGOUT());
                            }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid container item alignItems={'center'} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        justifyContent={'space-between'}
                        display={'flex'}
                    >
                        <Grid item>
                            <Typography
                                sx={styles.mainHeading}
                                text={'Click here to change your password'}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                text="Reset Password"
                                customStyles={{
                                    border: '1px solid #E5E6EB',
                                    padding: '10px 16px',
                                }}
                                onClick={() => {
                                    resetPassword(data.email);
                                    setShowConfirmation(!showConfirmation);
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default Security;

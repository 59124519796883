import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useChatService = () => {
    const { get, post, isLoading } = useAxios();

    const getChatsByCompany = useCallback(
        (companyId) => get(`bookkeeper/get/chats/${companyId}`),
        [get],
    );

    const getChats = useCallback(
        (chatId) => get(`bookkeeper/${chatId}/get/chat`),
        [get],
    );
    const createNewChatRoom = (companyId) =>
        post(
            `bookkeeper/${companyId}/create/chat`,
            {},
            'Your previous chat will be saved in History page',
        );

    const createChatItems = (companyId, chatId, data) =>
        post(`bookkeeper/create/chatitems/${companyId}/${chatId}`, data, '');

    const getFileAccess = useCallback(
        (filePath, companyId) =>
            get(
                `bookkeeper/get-file-access/${companyId}?file_path=${filePath}`,
            ),
        [get],
    );

    return {
        isLoading,
        getChats,
        getFileAccess,
        createChatItems,
        getChatsByCompany,
        createNewChatRoom,
    };
};

import { createContext, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import {
    LOGOUT,
    RESET_CHAT_SESSION,
    SET_CURRENT_USER_ERROR,
    SET_CURRENT_USER_PENDING,
    SET_CURRENT_USER_SUCCESS,
    UPDATE_COMPANY_SUCCESS,
} from '../store/actions';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.me);
    const navigate = useNavigate();
    // call this function when you want to authenticate the user
    const setUserData = useCallback(
        async (data) => {
            try {
                dispatch(SET_CURRENT_USER_PENDING({ loading: true }));
                dispatch(SET_CURRENT_USER_SUCCESS(data));
            } catch (err) {
                dispatch(SET_CURRENT_USER_ERROR(err));
            }
        },
        [dispatch],
    );

    // call this function to sign out logged in user
    const logout = useCallback(() => {
        try {
            dispatch(LOGOUT());
            dispatch(SET_CURRENT_USER_PENDING({ loading: true }));
            dispatch(SET_CURRENT_USER_SUCCESS(null));
            dispatch(UPDATE_COMPANY_SUCCESS(null));
            dispatch(RESET_CHAT_SESSION());
        } catch (err) {
            dispatch(SET_CURRENT_USER_ERROR(err));
        }
        persistor.purge();
        navigate('/login', { replace: true });
    }, [navigate, dispatch]);

    const value = useMemo(
        () => ({
            user,
            setUserData,
            logout,
        }),
        [user, setUserData, logout],
    );
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

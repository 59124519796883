import {
    FormHelperText,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import NestedDropDown from 'components/Transaction/TabsData/ImportBankTransaction/Components/ImportTransactionSelect/NestedDropDown';
import { useCallback, useEffect } from 'react';
import { formatAccountTitleRow, formatCurrencyAmount } from 'utils/helpers';

const DropDownTableRowEditMobile = ({
    themeMode,
    row,
    panelData,
    onListItemClick,
    changeDropDownLength,
    openDropDownIndex,
    handleDropDownToggle,
    isPanelDataLoading,
    handleDropDownClose,
    submitSecondaryTableRow,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    rowIndex,
    index,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
}) => {
    const onSaveClick = useCallback(() => {
        submitSecondaryTableRow(
            secondTableCategory,
            secondTableTransactionDetails,
            secondTableAmount,
            secondTableQuantity,
            rowIndex,
            index,
        );
    }, [
        index,
        rowIndex,
        secondTableAmount,
        secondTableCategory,
        secondTableQuantity,
        secondTableTransactionDetails,
        submitSecondaryTableRow,
    ]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                event.target.closest('.MuiTableRow-root') ||
                event.target.closest('.MuiMenu-paper') ||
                event.target.closest('.MuiPopover-root') ||
                event.target.closest('.stack-input') ||
                event.target.closest('.MuiPickersPopper-root')
            ) {
                return;
            }

            onSaveClick();
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onSaveClick]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSaveClick();
            }
        },
        [onSaveClick],
    );
    return (
        <Stack
            className="stack-input"
            key={row.id}
            onKeyDown={handleKeyPress}
            gap={1}
            sx={{
                p: 2,
            }}
        >
            <Stack direction="column">
                <Typography color="text.secondary">Description</Typography>
                <TextField
                    error={errorsSecondTable?.secondTableTransactionDetails}
                    type="text"
                    value={secondTableTransactionDetails}
                    onChange={handleSecondDescriptionChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.description && (
                    <FormHelperText error>
                        {errorsSecondTable?.description}
                    </FormHelperText>
                )}
            </Stack>

            <Stack direction="column">
                <NestedDropDown
                    item={panelData}
                    title={formatAccountTitleRow(secondTableCategory)}
                    plaidId={row?.id}
                    companyId={row?.company?.id}
                    onItemClick={onListItemClick}
                    transactionId={row?.id}
                    changeDropDownLength={changeDropDownLength}
                    isDropDownOpen={row?.id === openDropDownIndex}
                    handleDropDownToggle={handleDropDownToggle}
                    isPanelDataLoading={isPanelDataLoading}
                    handleDropDownClose={handleDropDownClose}
                    onRight
                />
            </Stack>
            <Stack direction="column">
                <TextField
                    error={errorsSecondTable?.item_total}
                    value={secondTableAmount}
                    onChange={handleSecondAmountChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.amount && (
                    <FormHelperText error>
                        {errorsSecondTable?.amount}
                    </FormHelperText>
                )}
            </Stack>
            {/* <Stack direction="column">
                <TextField
                    error={errorsSecondTable?.price}
                    value={secondTableQuantity}
                    onChange={handleSecondQuantityChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errorsSecondTable?.price && (
                    <FormHelperText error>
                        {errorsSecondTable?.price}
                    </FormHelperText>
                )}
            </Stack> */}
            {/* <Stack direction="column">
                <Stack direction="column">
                    <Typography color="text.secondary">Total Amount</Typography>
                    <Typography color="text.primary">
                        ${' '}
                        {formatCurrencyAmount(
                            row?.isThisTax
                                ? 0
                                : (+row?.item_total || 0) +
                                      (row?.taxAmount || 0),
                        )}
                    </Typography>
                </Stack>
            </Stack> */}
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <IconButton
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleRemoveTransactionItem(rowIndex, index, true);
                    }}
                >
                    <Trash />
                </IconButton>
            </Stack>
        </Stack>
    );
};

export default DropDownTableRowEditMobile;

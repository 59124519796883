import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as Source } from 'assets/icons/Source.svg';
import { ReactComponent as SourceDarkMode } from 'assets/icons/SourceDarkMode.svg';
import MyIcon from 'assets/icons/import.svg';
import { format } from 'date-fns';
import { formatCurrencyAmount } from 'utils/helpers';

export const ScanReceiptRowMobile = ({
    themeMode,
    onEditClick,
    editId,
    handleEditClick,
    handleCollapse,
    index,
    row,
    isCollapse,
    importPostTransaction,
    handleRemoveTransaction,
    isEdit,
    handleSourceModalOpen,
}) => {
    return (
        <Stack
            key={row.id}
            onClick={() => isEdit && handleEditClick(row, index, editId)}
            gap={1}
            sx={{
                p: 2,
            }}
        >
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Date</Typography>
                    <Typography color="text.primary">
                        {format(new Date(row?.date), 'MM-dd-yyyy')}
                    </Typography>
                </Stack>
                <Stack direction="column">
                    <Typography color="text.secondary">Invoice Name</Typography>
                    <Typography color="text.primary" maxWidth={200} noWrap>
                        {row?.description}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Amount</Typography>
                    <Typography color="text.primary">
                        $ {formatCurrencyAmount(row?.transaction_total || 0)}
                    </Typography>
                </Stack>
                <Stack direction="column">
                    <Typography color="text.secondary">
                        Vendor/Client
                    </Typography>
                    <Typography color="text.primary" maxWidth={200} noWrap>
                        {row?.counterparty_name}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Status</Typography>
                    <Typography
                        noWrap
                        sx={{
                            px: 1,
                            backgroundColor: !row?.is_paid
                                ? '#FEF3F2'
                                : '#ECFDF3',
                            color: !row?.is_paid ? '#B42318' : '#067647',
                            border: !row?.is_paid
                                ? ' 1px solid #FECDCA'
                                : '1px solid #ABEFC6',

                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                            textTransform: 'capitalize',
                        }}
                    >
                        {row?.is_paid ? 'Paid' : 'Unpaid'}
                    </Typography>
                </Stack>
                {row?.book_by_each_item ? (
                    isEdit || !!row?.transaction_items?.length ? (
                        <IconButton
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleCollapse(index);
                            }}
                        >
                            {isCollapse ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    ) : null
                ) : null}
            </Stack>
            <Stack
                width="100%"
                direction="row"
                gap={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                        color: 'text.secondary',
                        borderRadius: '8px',
                        borderColor: 'divider',
                        textTransform: 'none',
                    }}
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleSourceModalOpen(index);
                    }}
                    startIcon={
                        themeMode === 'dark' ? <SourceDarkMode /> : <Source />
                    }
                >
                    Source
                </Button>
                {isEdit && (
                    <Button
                        fullWidth
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={(e) => {
                            e?.stopPropagation();
                            importPostTransaction(index);
                        }}
                    >
                        <img
                            src={MyIcon}
                            alt="Icon"
                            style={{
                                width: 18,
                                height: 18,
                                marginRight: '8px',
                            }}
                        />
                        Import
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};

import { Box, Button, Divider, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
    calculateTaxForElement,
    distributeTax,
    getThisTaxElementIndex,
} from 'utils/helpers';
import DropDownTableRowEditMobile from './DropDownTableRowEditMobile';
import DropDownTableRowMobile from './DropDownTableRowMobile';

const DropDownTableMobile = ({
    themeMode,
    panelData,
    onListItemClick,
    changeDropDownLength,
    openDropDownIndex,
    handleDropDownToggle,
    isPanelDataLoading,
    handleDropDownClose,
    handleEditClickSecondTable,
    hanldeCloseEditSecondTable,
    editIdSecondTable,
    submitSecondaryTableRow,
    secondaryRows: data,
    setSecondaryRows: setData,
    addTransactionItem,
    rowIndex,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    handleAddTransactionItem,
    isEdit,
}) => {
    const [hasTax, setHasTax] = useState(null);

    useEffect(() => {
        setHasTax(getThisTaxElementIndex(data));
    }, [data]);

    const handleTaxClick = useCallback(
        (event, index) => {
            event.stopPropagation();
            setData((previousData) =>
                previousData?.map((item, indexNew) =>
                    indexNew === rowIndex
                        ? {
                              ...item,
                              transaction_items: distributeTax(
                                  item?.transaction_items,
                                  index,
                              ),
                          }
                        : item,
                ),
            );
        },
        [rowIndex, setData],
    );

    const handleTaxChangeCheckbox = useCallback(
        (event, index) => {
            event.stopPropagation();
            setData((previousData) =>
                previousData?.map((item, indexNew) =>
                    indexNew === rowIndex
                        ? {
                              ...item,
                              transaction_items: calculateTaxForElement(
                                  item?.transaction_items,
                                  index,
                              ),
                          }
                        : item,
                ),
            );
        },
        [rowIndex, setData],
    );

    return (
        <>
            <Stack
                gap={1}
                divider={<Divider flexItem />}
                sx={{
                    p: 1,
                    borderRadius: '10px',
                    backgroundColor:
                        themeMode === 'dark' ? '#323A46' : '#F9FAFB',
                }}
            >
                {data?.map((row, index) =>
                    editIdSecondTable === index ? (
                        <DropDownTableRowEditMobile
                            row={row}
                            themeMode={themeMode}
                            key={index}
                            hasTax={hasTax}
                            index={index}
                            rowIndex={rowIndex}
                            panelData={panelData}
                            onListItemClick={onListItemClick}
                            changeDropDownLength={changeDropDownLength}
                            openDropDownIndex={openDropDownIndex}
                            handleDropDownToggle={handleDropDownToggle}
                            isPanelDataLoading={isPanelDataLoading}
                            handleDropDownClose={handleDropDownClose}
                            submitSecondaryTableRow={submitSecondaryTableRow}
                            secondTableTransactionDetails={
                                secondTableTransactionDetails
                            }
                            secondTableAmount={secondTableAmount}
                            secondTableQuantity={secondTableQuantity}
                            secondTableCategory={secondTableCategory}
                            errorsSecondTable={errorsSecondTable}
                            handleSecondDescriptionChange={
                                handleSecondDescriptionChange
                            }
                            handleSecondAmountChange={handleSecondAmountChange}
                            handleSecondQuantityChange={
                                handleSecondQuantityChange
                            }
                            handleRemoveTransactionItem={
                                handleRemoveTransactionItem
                            }
                        />
                    ) : (
                        <DropDownTableRowMobile
                            isEdit={isEdit}
                            row={row}
                            themeMode={themeMode}
                            key={index}
                            hasTax={hasTax}
                            index={index}
                            rowIndex={rowIndex}
                            handleTaxClick={handleTaxClick}
                            handleTaxChangeCheckbox={handleTaxChangeCheckbox}
                            handleEditClickSecondTable={
                                handleEditClickSecondTable
                            }
                            editIdSecondTable={editIdSecondTable}
                            handleRemoveTransactionItem={
                                handleRemoveTransactionItem
                            }
                        />
                    ),
                )}
            </Stack>
            {isEdit && editIdSecondTable === null && (
                <Box display="flex" marginY="20px">
                    <Button
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                            color: '#0061DB',
                            textTransform: 'none',
                        }}
                        onClick={() => handleAddTransactionItem(rowIndex)}
                    >
                        + Transaction Item
                    </Button>
                </Box>
            )}
        </>
    );
};

export default DropDownTableMobile;

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export const Progress = ({ progress, customStyle }) => {
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={customStyle}
            />
        </Box>
    );
};

import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Typography } from '../shared';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useChatService } from '../store/services/chat.service';
import { useDispatch, useSelector } from 'react-redux';
import { Home } from './Home';
import { useTransactionService } from '../store/services/transactions.service';
import {
    RESET_SELECTED_CHAT_SESSION,
    SELECTED_CHAT_SESSION,
} from '../store/actions';

const makeStyles = ({ isScreenMobile, isScreenLowerThan1024, isLoading }) => ({
    root: {
        height: '90vh',
    },
    chatHistory: {
        p: isScreenLowerThan1024 ? 1 : '24px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        backgroundColor: (theme) => theme.backgroundColor.primary,
        ...(isScreenLowerThan1024 && { m: 1 }),
        height: isScreenLowerThan1024
            ? 'calc(94vh - 250px)'
            : 'calc(94vh - 30px)',
        overflowY: 'auto',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            width: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            margin: 1,
            borderRadius: '8px',
            backgroundColor: 'rgba(0,0,0,.1)',
        },
        justifyContent: isLoading ? 'center' : 'start',
        alignItems: isScreenLowerThan1024 ? 'start' : 'center',
    },

    previousDaysChat: {
        display: 'flex',
        alignItems: 'center',
        p: '12px 16px',
        borderRadius: '12px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: (theme) => theme.backgroundColor.secondary,
            transform: 'scale(1.03)',
        },
        '& p': {
            fontSize: '14px',
        },
    },
    selectedChatStyle: {
        backgroundColor: (theme) => theme.backgroundColor.secondary,
        '& p': {
            fontSize: '14px',
            fontWeight: 600,
            color: (theme) => `${theme.textColor.dark} !important`,
        },
    },
});

export const History = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [chatList, setChatList] = useState([]);
    const [transactionList, setTransactionList] = useState([]);
    const [selectedChatIndex, setSelectedChatIndex] = useState(-1);
    const [selectedChatSession, setSelectedChatSession] = useState([]);

    const { data: company } = useSelector((state) => state.company);

    const { getTransactionsByChat } = useTransactionService();
    const { getChatsByCompany, getChats } = useChatService();

    const isScreenMobile = useCustomMediaQuery('(max-width: 600px)');
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const styles = makeStyles({
        isScreenMobile,
        isScreenLowerThan1024,
        isLoading,
    });

    const handleSelectedChat = (chatId) => {
        setSelectedChatIndex(chatId);
        dispatch(SELECTED_CHAT_SESSION({ chatId }));
        Promise.all([
            getChats(chatList.find((chat) => chat.id === chatId).id),
            getTransactionsByChat(chatId),
        ])
            .then(([chat, transactions]) => {
                setSelectedChatSession(chat);
                setTransactionList(transactions);
            })
            .catch((error) => {
                // Handle errors here
                console.error('Error in handleSelectedChat:', error);
            });
    };

    useEffect(() => {
        if (company?.id) {
            getChatsByCompany(company.id).then((res) => {
                setIsLoading(false);
                setChatList(res);
            });
        }
        // return () => {
        //     dispatch(RESET_SELECTED_CHAT_SESSION());
        // };
    }, [getChatsByCompany, company?.id, dispatch]);

    return (
        <Grid
            container
            spacing={0}
            sx={styles.root}
            justifyContent={'space-between'}
        >
            <Grid
                container
                item
                xs={12}
                md={isScreenLowerThan1024 ? 12 : 2}
                lg={isScreenLowerThan1024 ? 12 : 2}
                sx={styles.chatHistory}
                spacing={1}
            >
                {isLoading ? (
                    <Typography text="Loading previous chats..." />
                ) : (
                    <Box width={'100%'}>
                        <Grid item>
                            <Typography
                                text="Previous 7 Days"
                                customStyles={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            {chatList.slice(0, 7).map((chat, index) => {
                                return (
                                    <Box
                                        key={chat.id}
                                        sx={{
                                            ...styles.previousDaysChat,
                                            ...(selectedChatIndex === chat.id &&
                                                styles.selectedChatStyle),
                                        }}
                                        onClick={() =>
                                            handleSelectedChat(chat.id)
                                        }
                                    >
                                        <Typography
                                            text={chat.title || 'New Chat'}
                                            customStyles={{
                                                color: (theme) =>
                                                    theme.primary.dark,
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </Grid>
                        <Grid item>
                            <Typography
                                text="Previous 30 Days"
                                customStyles={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            {chatList.slice(7, -1).map((chat, index) => {
                                return (
                                    <Box
                                        sx={{
                                            ...styles.previousDaysChat,
                                            ...(selectedChatIndex === chat.id &&
                                                styles.selectedChatStyle),
                                        }}
                                        onClick={() =>
                                            handleSelectedChat(chat.id)
                                        }
                                        key={chat.id}
                                    >
                                        <Typography
                                            text={chat.text || 'New Chat'}
                                            customStyles={{
                                                color: (theme) =>
                                                    theme.primary.dark,
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                        </Grid>
                    </Box>
                )}
            </Grid>

            {selectedChatIndex !== -1 ? (
                <Grid
                    item
                    xs={12}
                    md={isScreenLowerThan1024 ? 12 : 10}
                    lg={isScreenLowerThan1024 ? 12 : 10}
                    justifyContent={'center'}
                >
                    <Home
                        uploadedFiles={[]}
                        isHistoryFlow={true}
                        showCreateChatRoomButton={false}
                        chatSession={selectedChatSession}
                        transactionList={transactionList}
                    />
                </Grid>
            ) : (
                <Grid
                    item
                    xs={8}
                    display="flex"
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Typography
                        text={'No chat selected'}
                        customStyles={{
                            fontSize: '20px',
                            fontWeight: 600,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import { Grid } from '@mui/material';

const styles = {
    pagination: {
        '& ul': {
            justifyContent: 'flex-end',
        },
        '& .MuiButtonBase-root': {
            color: (theme) => theme.primary.dark,
            height: '48px',
            width: '48px',
            minWidth: '24px',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '17.07px',
        },
        '& .Mui-selected': {
            color: '#0061DB',
            backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#394150' : '#CCDFF8',
            fontSize: 14,
            fontWeight: 700,
        },
        '& .MuiSvgIcon-root': {
            height: '24px',
            width: '24px',
            color: (theme) => theme.backgroundColor.dark,
        },
    },
    select: {
        height: '48px',
        width: '74px',
        // border: (theme) => `1px solid ${theme.textColor.primary}`,
        // '&:hover': {
        //     border: (theme) => `1px solid ${theme.textColor.primary}`,
        // },
    },
};
export const TablePagination = ({ count, page, isMobileScreen, onChange }) => {
    // const [itemsPerPage, setItemsPerPage] = useState(10);
    return (
        <Grid container justifyContent={'flex-end'}>
            {/* {!isMobileScreen && (
                <Grid item display={'flex'} alignItems={'center'}>
                    <Typography
                        text={'Show Results'}
                        customStyles={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: (theme) => '#9EA3AE',
                        }}
                    />
                    <Select
                        sx={{
                            marginLeft: 1,
                            color: (theme) => theme.primary.dark,
                            ...styles.select,
                        }}
                        // variant="outlined"
                        value={itemsPerPage}
                        onChange={(event) =>
                            setItemsPerPage(event.target.value)
                        }
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </Grid>
            )} */}
            <Grid item>
                <Pagination
                    page={page}
                    shape="rounded"
                    sx={styles.pagination}
                    count={Math.ceil(count / 10)}
                    onChange={(e, pageNumber) => onChange(e, pageNumber)}
                />
            </Grid>
        </Grid>
    );
};

import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
    GET_AUTH_ME,
    LOGOUT,
    SET_CURRENT_USER_ERROR,
    SET_CURRENT_USER_PENDING,
    SET_CURRENT_USER_SUCCESS,
} from '../actions';

function* getLoggedInUserDetails({
    payload,
}: {
    payload: Record<string, any>,
}) {
    try {
        yield put(SET_CURRENT_USER_PENDING({ loading: true }));
        const result = yield call(payload.getLoggedInUserDetails);
        yield put(SET_CURRENT_USER_SUCCESS({ data: result }));
    } catch (err) {
        if (err.statusCode === 401) {
            yield put(SET_CURRENT_USER_SUCCESS({ data: null }));
            yield put(LOGOUT());
        }
        yield put(SET_CURRENT_USER_ERROR({ err }));
    }
}

export default function* authSaga() {
    yield all([takeEvery(GET_AUTH_ME, getLoggedInUserDetails)]);
}

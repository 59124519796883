import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    primary: {
        main: '#6C727F',
        dark: '#6C727F',
        light: '#FFF',
    },
    info: {
        main: '#F9FEFF',
    },
    error: {
        main: '#A94943',
    },
    text: {
        primary: '#49545B',
        secondary: '#606E77',
        light: '#FFF',
    },
    action: {
        selected: '#F1F7FA',
    },
    typography: {
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    textColor: {
        dark: '#121826',
        primary: '#6C727F', //'#1976d2',
        secondary: '#6C727F',
        light: '#9EA3AE',
        white: '#fff',
        warning: '#F97777',
    },

    borderColor: {
        textField: '#8497A1',
        button: '#606E77',
        divider: '#8497A2',
        toolbar: '#F1F7FA',
        inputField: '#E5E6EB',
    },
    chip: {
        admin: '#69A6B4',
        active: '#69A6B4',
        observer: '#A798FF',
        invited: '#49545B',
    },
    breadcrumb: {
        backgroundColor: '#FFECDB',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    border: 'none',
                    boxShadow: 'none',
                    ':before': {
                        display: 'none',
                    },
                },
            },
        },
    },
    backgroundColor: {
        primary: '#fff',
        secondary: '#F4F4F6',
        light: '#0061DB',
        warning: '#FACC15',
        uploadFileContainer: '#E5E6EB',
        tableHeader: '#F9F9FB',
        profileSettings: '#F4F4F6',
    },
    senderChat: {
        primary: '#F4F4F6',
        color: '#121826',
    },
    receiverChat: {
        primary: '#0061DB',
        color: '#FFFFFF',
    },
    progress: {
        main: '#00C791',
    },
    boxShadow: '10px 15px 50px 0px rgba(113, 128, 150, 0.24);',
});

export const darkTheme = createTheme({
    palette: {
        mode: 'dark', // Set the mode to 'dark'
    },
    primary: {
        main: '#121826',
        dark: '#fff',
        light: '#6C727F',
    },

    typography: {
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    textColor: {
        dark: '#F9FAFB',
        primary: '#9EA3AE',
        secondary: '#9EA3AE',
        light: '#6C727F',
        white: '#fff',
        warning: '#F97777',
    },

    backgroundColor: {
        primary: '#212936',
        secondary: '#121826',
        light: '#fff',
        warning: '#FACC15',
        uploadFileContainer: '#6C727F',
        tableHeader: '#212936',
        profileSettings: '#394150',
    },
    senderChat: {
        primary: '#394150',
        color: '#F9FAFB',
    },
    receiverChat: {
        primary: '#0061DB',
        color: '#FFFFFF',
    },

    //TODO: Need to update colors
    borderColor: {
        textField: '#8497A1',
        button: '#606E77',
        divider: '#8497A2',
        toolbar: '#F1F7FA',
        inputField: '#394150',
    },

    progress: {
        main: '#00C791',
    },

    boxShadow: '10px 15px 50px 0px rgba(17, 24, 39, 0.60)',
});

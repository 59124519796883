import { useState } from 'react';
import {
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Image from 'mui-image';
import CirclerImage from '../assets/icons/CirclerLeftImage.svg';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';
import GoogleIcon from '../assets/icons/google-icon.png';
import { TextField, Button } from '../shared';
import { useParams, useNavigate } from 'react-router-dom';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { CheckboxSelection as Checkbox } from '../shared';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { useAuthService } from '../store/services/auth.service';
import useNotification from '../hooks/useNotification';
import axios from 'axios';

const styles = {
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    mainHeading: {
        fontSize: '32px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },

    forgotPassword: {
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 500,
    },
    loginBtnStyle: {
        borderRadius: '8px',
        fontSize: '18px',
        fontWeight: 500,
    },
    otherSignInOptionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    otherSignInOptionsText: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
    },
    signInOption: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
    },
    googleSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '500',
    },
    otherSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        // color: '#004EAF', // Blue color
        cursor: 'pointer', // Optional: Change cursor on hover,
        fontWeight: 600,
        fontSize: '16px',
    },
};

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { resetPassword } = useAuthService();
    const [email, setEmail] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [sendNotification] = useNotification();
    const { themeMode } = useThemeToggle();
    const [error, setError] = useState('');
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');

    const handleSubmit = async () => {
        resetPassword(email);
        navigate('/login');
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    return (
        <Grid
            flex={{ xs: '1', sm: '2', md: '3' }}
            container
            sx={styles.container}
        >
            <Grid
                item
                position="relative"
                xs={12}
                md={6}
                lg={6}
                maxWidth={'700px'}
                display={{ xs: 'none', md: 'block', lg: 'block' }}
                overflow={{ xs: 'scroll', md: 'hidden', lg: 'hidden' }}
            >
                <Image alt="Left Content" src={CirclerImage} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                flex="1"
                alignContent="flex-start"
                justifyContent={'center'}
                px={{ xs: 2, sm: 4, md: 8 }}
                pt="50px"
                sx={{
                    // height: 'calc(100vh - 25px)',
                    overflow: 'auto',
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    maxWidth={'700px'}
                    padding={isScreenLowerThan1024 ? '24px 0' : '0 10%'}
                    gap={3}
                    textAlign={'start'}
                >
                    <Grid item>
                        {themeMode === 'dark' ? (
                            <DarkCirclerLogo
                                width="180.995px"
                                height="45.22px"
                            />
                        ) : (
                            <CirclerLogo width="180.995px" height="45.22px" />
                        )}
                    </Grid>
                    <Grid item>
                        <Typography sx={styles.mainHeading}>
                            Reset your password
                        </Typography>
                        <Typography sx={styles.subHeading}>
                            Please enter your email address to receive the link
                            to reset your password.
                        </Typography>
                    </Grid>

                    <Grid item>
                        <TextField
                            name="email"
                            placeholder="Email *"
                            type="text"
                            value={email}
                            onChange={handleChangeEmail}
                        />
                    </Grid>

                    {error && (
                        <Grid item>
                            <Typography
                                sx={{
                                    color: 'red',
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}
                            >
                                {error}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            text={
                                <Typography sx={styles.signInOption}>
                                    Submit
                                </Typography>
                            }
                            fullWidth
                            customStyles={styles.loginBtnStyle}
                            onClick={handleSubmit}
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        sx={styles.otherSignInOptionsContainer}
                    ></Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ForgotPassword;

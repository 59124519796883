import React from 'react';
import { LoadingButton as MuiButton } from '@mui/lab';

const styles = {
    button: {
        textTransform: 'capitalize',
        lineHeight: '16px',
        padding: '16px 28px',
        fontWeight: 600,
        fontSize: '13px',
        backgroundColor: '#0061DB',
        borderRadius: '8px',
        border: (theme) =>
            theme.palette.mode === 'dark'
                ? `1px solid ${theme.borderColor.inputField}`
                : '1px solid #E5E6EB',
        '&:hover': {
            border: (theme) =>
                theme.palette.mode === 'dark'
                    ? `1px solid ${theme.borderColor.inputField}`
                    : '1px solid #E5E6EB',
        },
    },
};

export const Button = ({
    variant = 'contained',
    text = '',
    loading = false,
    customStyles,
    color,
    size = 'medium',
    fullWidth = false,
    startIcon,
    endIcon,
    ...props
}) => {
    return (
        <MuiButton
            sx={{ ...styles.button, ...customStyles }}
            variant={variant}
            loading={loading}
            color={color}
            size={size}
            fullWidth={fullWidth}
            disableElevation
            startIcon={startIcon}
            endIcon={endIcon}
            {...props}
        >
            {text}
        </MuiButton>
    );
};

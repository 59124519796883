import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthService } from '../store/services/auth.service';
import { useAuth } from './useAuth';
import { SET_CURRENT_USER_SUCCESS } from '../store/actions';
import { useDispatch } from 'react-redux';

const ThemeToggleContext = createContext();

export const useThemeToggle = () => {
    const context = useContext(ThemeToggleContext);
    if (!context) {
        throw new Error(
            'useThemeToggle must be used within a ThemeToggleProvider',
        );
    }
    return context;
};

export const ThemeToggleProvider = ({ children }) => {
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { toggleDarkMode } = useAuthService();
    const [themeMode, setThemeMode] = useState();
    const toggleTheme = (e) => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        toggleDarkMode().then((res) => {
            dispatch(SET_CURRENT_USER_SUCCESS({ data: res }));
        });
    };

    useEffect(() => {
        setThemeMode(user.data?.darkmode_on ? 'dark' : 'light');
    }, [user.data?.darkmode_on]);

    return (
        <ThemeToggleContext.Provider value={{ themeMode, toggleTheme }}>
            {children}
        </ThemeToggleContext.Provider>
    );
};

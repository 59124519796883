import { Save } from '@mui/icons-material';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import {
    Box,
    Divider,
    Grid,
    Button as MUIButton,
    Typography as MUITypography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/icons/Edit.svg';
import { ReactComponent as EditIconDarkMode } from '../../assets/icons/EditDarkMode.svg';
import { ReactComponent as Help } from '../../assets/icons/HelpIcon.svg';
import { useThemeToggle } from '../../hooks/useThemeToggle';
import useTimezoneList from '../../hooks/useTimezoneList';
import { Autocomplete, Button, TextField, Typography } from '../../shared';
import { useCompanyService } from '../../store/services/company.service';
import { useCoreService } from '../../store/services/core.service';
import {
    UPDATE_COMPANY_ERROR,
    UPDATE_COMPANY_PENDING,
    UPDATE_COMPANY_SUCCESS,
} from '../../store/slices/company.slice';
import { ENTITY_TYPES } from '../../utils/constants';

const makeStyles = ({ isOnboardingFlow }) => ({
    companyInformationContainer: {
        //TODO: Need to check in Industry page from My Profile flow
        p: isOnboardingFlow ? '0px' : '32px',
        borderRadius: '12px',
        backgroundColor: !isOnboardingFlow
            ? (theme) => theme.backgroundColor.primary
            : 'unset',
    },
});

export const CompanyInformation = ({
    showIndustry = true,
    companyLabel,
    timezoneLabel,
    industryLabel,
    typeOfEntityLabel,
    isOnboardingFlow = false,
    showCompanyHeader = false,
    onCompanyChange = undefined,
}) => {
    const { data } = useSelector((state) => state.company);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { themeMode } = useThemeToggle();
    const { getIndustries, isLoading } = useCoreService();
    const { updateCompany } = useCompanyService();
    const [companies, setCompanies] = useState([]);
    const styles = makeStyles({ isOnboardingFlow });
    const [isEdit, setIsEdit] = useState(isOnboardingFlow);
    const timezoneList = useTimezoneList();
    const [company, setCompany] = useState({
        id: data?.id,
        name: data?.name,
        timezone: data?.timezone || {
            label: 'New York (Eastern Time - EST/EDT)',
            value: 'America/New_York',
        },
        industry: data?.industry,
        entity: data?.entity,
    });

    const { createStripeSession } = useCompanyService();

    const handleCreateStripeSession = useCallback(async () => {
        const response = await createStripeSession(data?.id);
        if (response?.url) {
            window.location.href = response?.url;
        }
    }, [data?.id, createStripeSession]);

    const handleCompanyChange = useCallback(
        (e, selectedItem) => {
            // If it's an Autocomplete component
            if (selectedItem) {
                const changeProperty = { ...company, [e]: selectedItem };
                setCompany(changeProperty);
                onCompanyChange?.(changeProperty);
            } else {
                // If it's a TextField component
                const { name, value } = e.target;
                const changeProperty = { ...company, [name]: value };
                setCompany(changeProperty);
                onCompanyChange?.(changeProperty);
            }
        },
        [company, onCompanyChange],
    );

    const handleUpdateCompany = (e) => {
        setIsEdit(!isEdit);
        dispatch(UPDATE_COMPANY_PENDING({ loading: true }));
        updateCompany(company.id, {
            name: company.name,
            industry: company.industry.value,
            timezone: company.timezone.value,
            entity: company.entity.value,
        })
            .then((res) => {
                dispatch(
                    UPDATE_COMPANY_SUCCESS({
                        data: {
                            ...res,
                            timezone: {
                                label: res.timezone,
                                value: res.timezone,
                            },
                            industry: {
                                label: company.industry.value,
                                value: company.industry.value,
                            },
                            entity: {
                                label: res.entity,
                                value: res.entity,
                            },
                        },
                    }),
                );
            })
            .catch((err) => {
                dispatch(UPDATE_COMPANY_ERROR({ err }));
            });
    };

    useEffect(() => {
        getIndustries()
            .then((res) => setCompanies(res))
            .catch((err) => console.error(err));
    }, [getIndustries]);

    return (
        <Grid
            item
            container
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles.companyInformationContainer}
        >
            {showCompanyHeader && (
                <>
                    <Grid item>
                        <Typography
                            text={'Company Information'}
                            customStyles={{
                                fontWeight: 700,
                                fontSize: 18,
                            }}
                        />
                        <Typography
                            text={
                                'Here you can update your company related information.'
                            }
                            customStyles={{
                                fontWeight: 500,
                                fontSize: 14,
                                color: (theme) => theme.primary.dark,
                            }}
                        />
                    </Grid>

                    <Grid item>
                        {!isEdit ? (
                            <Button
                                text="Edit"
                                startIcon={
                                    themeMode === 'dark' ? (
                                        <EditIconDarkMode fill={'#FFF'} />
                                    ) : (
                                        <EditIcon fill={'#FFF'} />
                                    )
                                }
                                customStyles={{
                                    background: (theme) =>
                                        theme.backgroundColor.primary,
                                    color: (theme) => theme.primary.dark,
                                    border: '1px solid #E5E6EB',
                                    padding: '10px 16px',
                                    '&:hover': {
                                        backgroundColor: (theme) =>
                                            theme.backgroundColor.primary,
                                    },
                                }}
                                onClick={() => setIsEdit(!isEdit)}
                            />
                        ) : (
                            <Button
                                text="Save Changes"
                                startIcon={<Save stroke={'#FFF'} />}
                                customStyles={{
                                    background: (theme) =>
                                        theme.backgroundColor.light,
                                    color: (theme) => theme.primary.light,
                                    border: '1px solid #E5E6EB',
                                    padding: '10px 16px',
                                    '&:hover': {
                                        backgroundColor: (theme) =>
                                            theme.backgroundColor.light,
                                    },
                                }}
                                onClick={() => handleUpdateCompany()}
                                loading={isLoading}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box width={'100%'}>
                            <Divider />
                        </Box>
                    </Grid>
                </>
            )}

            <Grid container item alignItems={'center'} spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        fullWidth
                        name={'name'}
                        label={companyLabel}
                        value={company.name}
                        placeholder="Company Name"
                        onChange={handleCompanyChange}
                        disabled={!isEdit}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                        options={timezoneList}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Continent/City"
                        name="timezone"
                        isSearchField={false}
                        label={timezoneLabel}
                        disabled={!isEdit}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                        onChange={(e, selectedItem) => {
                            handleCompanyChange('timezone', selectedItem);
                        }}
                        value={company?.timezone}
                        disableClearable
                        blurOnSelect
                    />
                </Grid>
                {showIndustry && (
                    <Grid item xs={12} md={6} lg={6}>
                        <Autocomplete
                            options={companies.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                            labelKey="label"
                            valueKey="value"
                            placeholder="Select Industry"
                            name="industry"
                            isSearchField={false}
                            label={industryLabel}
                            labelIcon={<Help />}
                            labelIconText={
                                'You will not be able to modify this later, but you can add custom accounts to your default COA.'
                            }
                            customLabelStyles={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#4D5461',
                            }}
                            customStyle={{
                                color: (theme) => theme.textColor.primary,
                            }}
                            onChange={(e, selectedItem) => {
                                handleCompanyChange('industry', selectedItem);
                            }}
                            value={company.industry}
                        />
                        <Typography
                            text={'This helps determine the appropriate COA'}
                            customStyles={{
                                mt: 1,
                                fontWeight: 400,
                                fontSize: 14,
                                color: '#6C727F',
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6} lg={6}>
                    <Autocomplete
                        options={ENTITY_TYPES}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select entity"
                        name="entity"
                        isSearchField={false}
                        label={typeOfEntityLabel}
                        labelIcon={<Help />}
                        labelIconText={
                            "You will not be able to modify this later, but don't worry. This doesn't affect COA."
                        }
                        disabled={!isOnboardingFlow}
                        customLabelStyles={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#D3D5DA'
                                    : '#4D5461',
                        }}
                        customStyle={{
                            color: (theme) => theme.textColor.primary,
                        }}
                        onChange={(e, selectedItem) => {
                            handleCompanyChange('entity', selectedItem);
                        }}
                        value={company.entity}
                    />
                    {isOnboardingFlow && (
                        <Typography
                            text={
                                "None if it's not a US entity or not incorporated"
                            }
                            customStyles={{
                                mt: 1,
                                fontWeight: 400,
                                fontSize: 14,
                                color: '#6C727F',
                            }}
                        />
                    )}
                </Grid>
                {!!!isOnboardingFlow && (
                    <Grid item xs={12} md={6} lg={6} alignSelf="end">
                        <MUIButton
                            fullWidth
                            variant="outlined"
                            startIcon={
                                themeMode === 'dark' ? (
                                    <PaymentsOutlinedIcon />
                                ) : (
                                    <PaymentsOutlinedIcon />
                                )
                            }
                            sx={{
                                paddingY: '14px',
                                background: (theme) =>
                                    theme.backgroundColor.primary,
                                color: (theme) => theme.primary.dark,
                                border: '1px solid #E5E6EB',
                                '&:hover': {
                                    border: '1px solid #E5E6EB',
                                },
                            }}
                            onClick={handleCreateStripeSession}
                        >
                            <MUITypography
                                variant="subtitle2"
                                textTransform="capitalize"
                            >
                                Manage Billing
                            </MUITypography>
                        </MUIButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LoadingButton } from '@mui/lab';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TableCell,
    TableRow,
    TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { ReactComponent as Source } from 'assets/icons/Source.svg';
import { ReactComponent as SourceDarkMode } from 'assets/icons/SourceDarkMode.svg';
import { useCallback, useEffect, useRef } from 'react';

export const ScanReceiptEditRow = ({
    themeMode,
    onEditClick,
    editId,
    handleEditClick,
    handleCollapse,
    index,
    row,
    isCollapse,
    onSave,
    description,
    handleDescriptionChange,
    transactionDate,
    setTransactionDate,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    amount,
    handleAmountChange,
    errors,
    isSubmitLoading,
    handleVendorChange,
    vendor,
    handlePaymentDropdownOpen,
    isPaymentLoading,
    paymentStatuses,
}) => {
    const rowRef = useRef(null);

    const onSaveClick = useCallback(() => {
        onSave(
            description,
            transactionDate,
            selectedPaymentStatus,
            amount,
            vendor,
            index,
        );
    }, [
        amount,
        description,
        index,
        onSave,
        selectedPaymentStatus,
        transactionDate,
        vendor,
    ]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (rowRef.current && !rowRef.current.contains(event.target)) {
                if (
                    event.target.closest('.tableRowDesktop') ||
                    event.target.closest('.MuiMenu-paper') ||
                    event.target.closest('.MuiPickersPopper-root')
                ) {
                    return;
                }

                onSaveClick();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onSaveClick]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSaveClick();
            }
        },
        [onSaveClick],
    );

    return (
        <TableRow
            hover
            // onClick={() => handleEditClick(row, index, editId)}
            ref={rowRef}
            onKeyDown={handleKeyPress}
            sx={{
                cursor: 'pointer',
            }}
        >
            <TableCell sx={{ maxWidth: 140, overflow: 'hidden' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                        components={['DatePicker']}
                        sx={{
                            overflow: 'hidden',
                            paddingTop: 0,
                        }}
                    >
                        <DatePicker
                            value={transactionDate}
                            onChange={(newValue) =>
                                setTransactionDate(newValue)
                            }
                            fullWidth
                            disableFuture
                            disableHighlightToday
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    height: '40px',
                                    minHeight: '40px',
                                    '& input': {
                                        padding: '8px 12px',
                                        paddingRight: 0,
                                        marginRight: 0,
                                    },
                                    maxWidth: 150,
                                    overflow: 'hidden',
                                    '& .MuiInputAdornment-root': {
                                        marginLeft: '-50px',
                                    },
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        borderRadius: '16px',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : 'none',
                                                borderRadius: '16px',
                                            },
                                            '&:hover fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : 'none',
                                                borderRadius: '16px',
                                            },
                                            '&.Mui-focused fieldset': {
                                                border: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : 'none',
                                                borderRadius: '16px',
                                            },
                                        },
                                    }}
                                />
                            )}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </TableCell>
            <TableCell sx={{ maxWidth: 200 }}>
                <TextField
                    fullWidth
                    error={errors?.description}
                    type="text"
                    value={description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.description && (
                    <FormHelperText error>{errors?.description}</FormHelperText>
                )}
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
                <TextField
                    error={errors?.amount}
                    value={amount}
                    onChange={handleAmountChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            maxWidth: 100,
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.amount && (
                    <FormHelperText error>{errors?.amount}</FormHelperText>
                )}
            </TableCell>
            <TableCell sx={{ maxWidth: 150 }}>
                <TextField
                    error={errors?.vendor}
                    type="text"
                    value={vendor}
                    onChange={handleVendorChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.vendor && (
                    <FormHelperText error>{errors?.vendor}</FormHelperText>
                )}
            </TableCell>
            <TableCell sx={{ maxWidth: 75 }}>
                <FormControl variant="outlined" size="small">
                    <Select
                        error={errors?.paymentStatus}
                        value={selectedPaymentStatus}
                        onChange={handlePaymentStatusChange}
                        // onOpen={handlePaymentDropdownOpen}
                        sx={{
                            maxWidth: 150,
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6', // Match the background color
                            borderRadius: '6px', // Match border radius
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Match border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Hover effect
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Focus effect
                            },
                        }}
                    >
                        {/* {isPaymentLoading ? (
                            <MenuItem
                                disabled
                                sx={{
                                    justifyContent: 'center',
                                }}
                                value={selectedPaymentStatus}
                            >
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            paymentStatuses?.map((status) => (
                                <MenuItem key={status.id} value={status}>
                                    {status.label}
                                </MenuItem>
                            ))
                        )} */}
                        {paymentStatuses?.map((status) => (
                            <MenuItem key={status.id} value={status?.id}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {errors?.paymentStatus && (
                    <FormHelperText error>
                        {errors?.paymentStatus}
                    </FormHelperText>
                )}
            </TableCell>
            <TableCell>
                <Grid
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    {/* <IconButton
                        onClick={(e) => {
                            e?.stopPropagation();
                        }}
                    >
                        {themeMode === 'dark' ? <SourceDarkMode /> : <Source />}
                    </IconButton> */}
                    {''}
                </Grid>
            </TableCell>
            <TableCell>
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                >
                    {/* <LoadingButton
                        loading={isSubmitLoading}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={(e) => {
                            e?.stopPropagation();
                            onSave(
                                description,
                                transactionDate,
                                selectedPaymentStatus,
                                amount,
                                vendor,
                                index,
                            );
                        }}
                    >
                        Save
                    </LoadingButton> */}
                    {/* <Button
                        text="Edit"
                        startIcon={
                            themeMode === 'dark' ? (
                                <EditIconDarkMode fill={'#FFF'} />
                            ) : (
                                <EditIcon fill={'#FFF'} />
                            )
                        }
                        sx={{
                            background: (theme) =>
                                theme.backgroundColor.primary,
                            color: 'inherit',
                            borderRadius: '8px',
                            textTransform: 'none',
                            border: '1px solid #E5E6EB',
                            '&:hover': {
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.primary,
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onEditClick(index);
                        }}
                    >
                        Edit
                    </Button> */}
                    <IconButton
                        color={isCollapse ? 'inherit' : 'default'}
                        onClick={(e) => {
                            e?.stopPropagation();
                            handleCollapse(index);
                        }}
                    >
                        {isCollapse ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useDashboardService = () => {
    const { get, isLoading } = useAxios();

    const getInsightsView = useCallback(
        () => get('/dashboard/insights_view'),
        [get],
    );

    const getDashboardView = useCallback(
        (companyId, currency = 'USD', endDate) => {
            let url = '/dashboard/dashboard_view?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );

    const getDashboardSecondView = useCallback(
        (companyId, currency = 'USD', endDate) => {
            let url = '/dashboard/dashboard_second_view?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );

    const getNetIncomeView = useCallback(
        (companyId, currency = 'USD', endDate) => {
            let url = '/dashboard/net_income_view?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            if (endDate !== undefined) {
                url += `&end_date=${endDate}`;
            }
            return get(url);
        },
        [get],
    );

    const getOperatingExpenseMonthsView = useCallback(
        (companyId, currency = 'USD') => {
            let url = '/dashboard/operating_expenses_months?';
            if (companyId !== undefined) {
                url += `company_id=${companyId}`;
            }
            if (currency !== undefined) {
                url += `&currency=${currency}`;
            }
            return get(url);
        },
        [get],
    );

    return {
        isLoading,
        getInsightsView,
        getDashboardView,
        getNetIncomeView,
        getDashboardSecondView,
        getOperatingExpenseMonthsView,
    };
};

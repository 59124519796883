import React from 'react';
import { BarChart } from '../shared';
import { calculateStepSize, formatValue } from '../utils/helpers';

const arePropsEqual = (prevProps, nextProps) => {
    // Your custom prop comparison logic here
    return (
        prevProps.isDarkMode === nextProps.isDarkMode &&
        prevProps.data.datasets.length === nextProps.data.datasets.length
        // Add other relevant prop comparisons
        // Example: prevProps.data === nextProps.data
    );
};

const CashInCashOutBar = ({ data, isDarkMode }) => {
    // Calculate dynamic stepSize, max, and min based on data
    const maxData = Math.max(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );
    const minData = Math.min(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );
    const range = maxData - minData;
    const stepSize = calculateStepSize(range, 5);

    const cashInOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Bar Charts',
            },
            datalabels: {
                display: false,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#9EA3AE',
                },
            },
            y: {
                grid: {
                    color: isDarkMode ? '#6C727F' : '#E5E6EB', // X-axis grid color
                },
                border: {
                    display: false,
                },

                ticks: {
                    callback: function (value, index, values) {
                        return formatValue(value);
                    },
                    crossAlign: 'far',
                    color: '#9EA3AE',
                    stepSize: stepSize,
                    max: maxData + stepSize, // Set the upper limit
                    min: minData - stepSize, // Set the lower limit
                },
            },
        },
    };

    return <BarChart options={cashInOptions} data={data} height={230} />;
};

export default React.memo(CashInCashOutBar, arePropsEqual);

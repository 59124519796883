import React from 'react';
import { calculateStepSize, formatValue } from '../utils/helpers';
import { BarChart } from '../shared';

const arePropsEqual = (prevProps, nextProps) => {
    // Your custom prop comparison logic here
    return (
        prevProps.data.datasets.length === nextProps.data.datasets.length
        // Add other relevant prop comparisons
        // Example: prevProps.data === nextProps.data
    );
};

const transformData = (cashPositionData) => {
    const labels = cashPositionData.labels;
    const cashBalances = cashPositionData.datasets.map(
        (entry) => entry.data[0],
    );
    return {
        labels: labels,
        datasets: [
            {
                label: 'Cash Balance',
                data: cashBalances,
                backgroundColor: '#00C791',
                barThickness: 12,
                maxBarThickness: 12,
                borderRadius: 5,
            },
        ],
    };
};

const CashPositionChart = ({ data, isDarkMode }) => {
    // Calculate dynamic stepSize, max, and min based on data
    const maxData = Math.max(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );

    const minData = Math.min(
        ...data.datasets.flatMap((dataset) => dataset.data),
    );

    const range = maxData - minData;
    const stepSize = calculateStepSize(range, 5);

    const revenueOptions = {
        plugins: {
            legend: {
                position: 'top',
                display: false,
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart - Stacked',
            },
            datalabels: {
                display: false, // Set to false to hide labels
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#9EA3AE',
                },
            },
            y: {
                stacked: false,
                grid: {
                    color: isDarkMode ? '#6C727F' : '#E5E6EB',
                },
                border: {
                    display: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        return formatValue(value);
                    },
                    color: '#9EA3AE',
                    crossAlign: 'far',
                    stepSize: stepSize,
                    max: maxData, // Set the upper limit
                    min: minData, // Set the lower limit
                },
            },
        },
    };

    const transformedData = transformData(data);

    return <BarChart options={revenueOptions} data={transformedData} />;
};

export default React.memo(CashPositionChart, arePropsEqual);

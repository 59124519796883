import React, { useState, useEffect } from 'react';

export const AnimatedDots = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Add a dot to the current dots
            setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'));
        }, 500); // Adjust the timing as needed (500ms in this example)

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return <span>{dots}</span>;
};

import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const DropDown = ({
    label,
    options,
    value,
    onChange,
    variant = 'standard',
    id,
    width,
    customStyles,
    placeHolder,
    minWidth = '230px',
}) => {
    return (
        <FormControl variant={variant} sx={{ m: 1, minWidth, width }}>
            {label && (
                <InputLabel
                    id={`${id}-label`}
                    sx={{ color: (theme) => theme.textColor.dark }}
                >
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={`${id}-label`}
                id={id}
                value={value}
                onChange={onChange}
                label={label}
                disableUnderline
                placeholder={placeHolder}
                sx={{ ...customStyles }}
            >
                {/* <MenuItem value="">
                    <em>None</em>
                </MenuItem> */}
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useCallback, useEffect, useState } from 'react';

export const ScanReceiptEditRowMobile = ({
    themeMode,
    onEditClick,
    editId,
    handleEditClick,
    handleCollapse,
    index,
    row,
    isCollapse,
    onSave,
    description,
    handleDescriptionChange,
    transactionDate,
    setTransactionDate,
    selectedPaymentStatus,
    handlePaymentStatusChange,
    amount,
    handleAmountChange,
    errors,
    isSubmitLoading,
    handleVendorChange,
    vendor,
    handlePaymentDropdownOpen,
    isPaymentLoading,
    paymentStatuses,
}) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const onSaveClick = useCallback(() => {
        onSave(
            description,
            transactionDate,
            selectedPaymentStatus,
            amount,
            vendor,
            index,
        );
    }, [
        amount,
        description,
        index,
        onSave,
        selectedPaymentStatus,
        transactionDate,
        vendor,
    ]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isDatePickerOpen) return;
            if (
                event.target.closest('.MuiTableRow-root') ||
                event.target.closest('.MuiMenu-paper') ||
                event.target.closest('.stack-input-2') ||
                event.target.closest('.MuiBox-root') ||
                event.target.closest('.MuiPickersPopper-root')
            ) {
                return;
            }

            onSaveClick();
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isDatePickerOpen, onSaveClick]);

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                onSaveClick();
            }
        },
        [onSaveClick],
    );

    return (
        <Stack
            className="stack-input-2"
            key={row.id}
            gap={1}
            sx={{
                p: 2,
            }}
            onKeyDown={handleKeyPress}
        >
            <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack direction="column">
                    <Typography color="text.secondary">Date</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={['DatePicker']}
                            sx={{ paddingTop: 0 }}
                        >
                            <DatePicker
                                onOpen={() => {
                                    setIsDatePickerOpen(true);
                                }}
                                onClose={() => {
                                    setIsDatePickerOpen(false);
                                }}
                                className="date-input-ui"
                                value={transactionDate}
                                onChange={(newValue) =>
                                    setTransactionDate(newValue)
                                }
                                disableFuture
                                disableHighlightToday
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6',
                                        height: '40px',
                                        minHeight: '40px',
                                        '& input': {
                                            padding: '8px 12px',
                                        },
                                    },
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            bgcolor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6',
                                            borderRadius: '16px',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : 'none',
                                                    borderRadius: '16px',
                                                },
                                                '&:hover fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : 'none',
                                                    borderRadius: '16px',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    border: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : 'none',
                                                    borderRadius: '16px',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Stack>
            </Stack>
            <Stack direction="column">
                <Typography color="text.secondary">Description</Typography>
                <TextField
                    error={errors?.description}
                    type="text"
                    value={description}
                    onChange={handleDescriptionChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.description && (
                    <FormHelperText error>{errors?.description}</FormHelperText>
                )}
            </Stack>
            <Stack direction="column">
                <Typography color="text.secondary">Amount</Typography>
                <TextField
                    error={errors?.amount}
                    value={amount}
                    onChange={handleAmountChange}
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.amount && (
                    <FormHelperText error>{errors?.amount}</FormHelperText>
                )}
            </Stack>
            <Stack direction="column">
                <Typography color="text.secondary">Vendor</Typography>
                <TextField
                    error={errors?.vendor}
                    type="text"
                    value={vendor}
                    onChange={handleVendorChange}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                        bgcolor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#F4F4F6',
                        borderRadius: '6px',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB',
                                borderRadius: '8px',
                            },
                        },
                    }}
                />
                {errors?.vendor && (
                    <FormHelperText error>{errors?.vendor}</FormHelperText>
                )}
            </Stack>
            <Stack direction="column">
                <Typography color="text.secondary">Payment Status</Typography>
                <FormControl variant="outlined" size="small">
                    <Select
                        error={errors?.paymentStatus}
                        value={selectedPaymentStatus}
                        onChange={handlePaymentStatusChange}
                        // onOpen={handlePaymentDropdownOpen}
                        sx={{
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#394150'
                                    : '#F4F4F6', // Match the background color
                            borderRadius: '6px', // Match border radius
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Match border color
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Hover effect
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? 'none'
                                        : '#E5E6EB', // Focus effect
                            },
                        }}
                    >
                        {paymentStatuses?.map((status) => (
                            <MenuItem key={status.id} value={status?.id}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {errors?.paymentStatus && (
                    <FormHelperText error>
                        {errors?.paymentStatus}
                    </FormHelperText>
                )}
            </Stack>
            <Stack direction="column">
                <IconButton
                    color={isCollapse ? 'inherit' : 'default'}
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleCollapse(index);
                    }}
                >
                    {isCollapse ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )}
                </IconButton>
            </Stack>
        </Stack>
    );
};

import { Box, Divider, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Typography } from '../../shared';
import { INDUSTRIES } from '../../utils/constants';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { useSelector } from 'react-redux';

const styles = {
    industryInformation: {
        p: '32px',
        borderRadius: '12px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    icons: {
        // p: '20px 12px 6px 12px!important',
        borderRadius: '12px',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: (theme) => theme.backgroundColor.secondary,
        },
    },
    selectedOption: {
        border: '1px solid #0061DB',
        backgroundColor: 'rgba(0, 97, 219, 0.10)',
        '& p': {
            fontSize: '14px',
            fontWeight: 500,
            color: '#0061DB',
        },
        '& path, & circle, & rect, & polygon, & line, & polyline, & ellipse': {
            transition: 'fill 0.3s, stroke 0.3s',
        },
        '&.selected path, &.selected circle, &.selected rect, &.selected polygon, &.selected line, &.selected polyline, &.selected ellipse':
            {
                stroke: (theme) => '#0061DB',
            },
    },
};

function Industry() {
    const {
        data: { industry },
    } = useSelector((state) => state.company);

    const [selectedIndustry] = useState(industry.label);
    const isMobileScreen = useCustomMediaQuery('(max-width: 600px)');

    // const handleSelectedIndustry = (id) => {
    //     setSelectedIndustry(id);
    // };

    return (
        <Grid
            item
            container
            spacing={2}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={styles.industryInformation}
        >
            <Grid item>
                <Typography
                    text={'Industry'}
                    customStyles={{
                        fontWeight: 700,
                        fontSize: 18,
                    }}
                />
            </Grid>

            <Grid item xs={12} py={3}>
                <Box width={'100%'}>
                    <Divider />
                </Box>
            </Grid>

            <Grid
                item
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={2}
            >
                {INDUSTRIES.map((industry, index) => {
                    return (
                        <Grid
                            item
                            xs={isMobileScreen ? 12 : 6}
                            md={4}
                            lg={4}
                            key={index}
                            // onClick={() => handleSelectedIndustry(index)}
                            sx={{
                                ...styles.icons,
                                ...styles.svg,
                                ...(selectedIndustry === industry.name &&
                                    styles.selectedOption),
                            }}
                            className={
                                selectedIndustry === industry.name
                                    ? 'selected'
                                    : ''
                            }
                        >
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                pr={2}
                                pb={2}
                            >
                                <Box display={'flex'} alignItems={'center'}>
                                    {industry.icon}&nbsp;&nbsp;&nbsp;&nbsp;
                                </Box>
                                <Typography
                                    text={industry.name}
                                    customStyles={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: (theme) =>
                                            theme.textColor.primary,
                                    }}
                                />
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}

export default Industry;

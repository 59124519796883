import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: null,
    error: null,
};

const prepare = (payload, meta, error) => {
    return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        GET_COMPANY_DETAILS_PENDING: {
            reducer: (state, { payload }) => {
                state.loading = payload.loading;
            },
            prepare,
        },
        GET_COMPANY_DETAILS_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.loading = false;
                state.error = null;
            },
            prepare,
        },
        GET_COMPANY_DETAILS_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.loading = false;
            },
            prepare,
        },
        CREATE_COMPANY_PENDING: {
            reducer: (state, { payload }) => {
                state.loading = payload.loading;
            },
            prepare,
        },
        CREATE_COMPANY_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.loading = false;
            },
            prepare,
        },
        CREATE_COMPANY_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.loading = false;
            },
            prepare,
        },
        UPDATE_COMPANY_PENDING: {
            reducer: (state, { payload }) => {
                state.loading = payload.loading;
            },
            prepare,
        },
        UPDATE_COMPANY_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.loading = false;
            },
            prepare,
        },
        UPDATE_COMPANY_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.loading = false;
            },
            prepare,
        },
    },
});

export const {
    GET_COMPANY_DETAILS_PENDING,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_ERROR,
    CREATE_COMPANY_PENDING,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_ERROR,
    UPDATE_COMPANY_PENDING,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_ERROR,
} = companySlice.actions;

export default companySlice.reducer;

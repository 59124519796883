import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
    calculateTaxForElement,
    distributeTax,
    getThisTaxElementIndex,
    toggleIsTaxed,
    toggleIsThisTax,
} from 'utils/helpers';
import DropDownTableRow from './DropDownTableRow';
import DropDownTableRowEdit from './DropDownTableRowEdit';

const DropDownTable = ({
    themeMode,
    panelData,
    onListItemClick,
    changeDropDownLength,
    openDropDownIndex,
    handleDropDownToggle,
    isPanelDataLoading,
    handleDropDownClose,
    handleEditClickSecondTable,
    hanldeCloseEditSecondTable,
    editIdSecondTable,
    submitSecondaryTableRow,
    secondaryRows: data,
    setSecondaryRows: setData,
    addTransactionItem,
    rowIndex,
    secondTableTransactionDetails,
    secondTableAmount,
    secondTableQuantity,
    secondTableCategory,
    errorsSecondTable,
    handleSecondDescriptionChange,
    handleSecondAmountChange,
    handleSecondQuantityChange,
    handleRemoveTransactionItem,
    handleAddTransactionItem,
    parentRow,
    isEdit,
}) => {
    const [hasTax, setHasTax] = useState(null);

    useEffect(() => {
        setHasTax(getThisTaxElementIndex(data));
    }, [data]);

    const handleTaxClick = useCallback(
        (event, index) => {
            event.stopPropagation();
            const value = event?.target?.checked;
            setData((previousData) =>
                previousData?.map((item, indexNew) =>
                    indexNew === rowIndex
                        ? {
                              ...item,
                              transaction_items: toggleIsThisTax(
                                  item?.transaction_items,
                                  index,
                                  value,
                              ),
                          }
                        : item,
                ),
            );
        },
        [rowIndex, setData],
    );

    const handleTaxChangeCheckbox = useCallback(
        (event, index) => {
            event.stopPropagation();
            const value = event?.target?.checked;
            setData((previousData) =>
                previousData?.map((item, indexNew) =>
                    indexNew === rowIndex
                        ? {
                              ...item,
                              transaction_items: toggleIsTaxed(
                                  item?.transaction_items,
                                  index,
                                  value,
                              ),
                          }
                        : item,
                ),
            );
        },
        [rowIndex, setData],
    );

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor:
                                themeMode === 'dark' ? '#323A46' : '#F9FAFB',
                        }}
                    >
                        <TableCell>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                Transaction details
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                textAlign="center"
                            >
                                Category
                            </Typography>
                        </TableCell>
                        {/* <TableCell>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                Amount
                            </Typography> */}
                        {/* </TableCell>
                        <TableCell>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                Quantity
                            </Typography>
                        </TableCell> */}
                        <TableCell>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                            >
                                Total Amount
                            </Typography>
                        </TableCell>
                        {isEdit && (
                            <TableCell>
                                <Typography
                                    variant="subtitle2"
                                    color="text.secondary"
                                >
                                    Tax allocation
                                </Typography>
                            </TableCell>
                        )}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row, index) =>
                        editIdSecondTable === index ? (
                            <DropDownTableRowEdit
                                row={row}
                                themeMode={themeMode}
                                key={index}
                                hasTax={hasTax}
                                index={index}
                                rowIndex={rowIndex}
                                panelData={panelData}
                                onListItemClick={onListItemClick}
                                changeDropDownLength={changeDropDownLength}
                                openDropDownIndex={openDropDownIndex}
                                handleDropDownToggle={handleDropDownToggle}
                                isPanelDataLoading={isPanelDataLoading}
                                handleDropDownClose={handleDropDownClose}
                                submitSecondaryTableRow={
                                    submitSecondaryTableRow
                                }
                                secondTableTransactionDetails={
                                    secondTableTransactionDetails
                                }
                                secondTableAmount={secondTableAmount}
                                secondTableQuantity={secondTableQuantity}
                                secondTableCategory={secondTableCategory}
                                errorsSecondTable={errorsSecondTable}
                                handleSecondDescriptionChange={
                                    handleSecondDescriptionChange
                                }
                                handleSecondAmountChange={
                                    handleSecondAmountChange
                                }
                                handleSecondQuantityChange={
                                    handleSecondQuantityChange
                                }
                                handleRemoveTransactionItem={
                                    handleRemoveTransactionItem
                                }
                                parentRow={parentRow}
                            />
                        ) : (
                            <DropDownTableRow
                                isEdit={isEdit}
                                parentRow={parentRow}
                                row={row}
                                themeMode={themeMode}
                                key={index}
                                hasTax={hasTax}
                                index={index}
                                rowIndex={rowIndex}
                                handleTaxClick={handleTaxClick}
                                handleTaxChangeCheckbox={
                                    handleTaxChangeCheckbox
                                }
                                handleEditClickSecondTable={
                                    handleEditClickSecondTable
                                }
                                editIdSecondTable={editIdSecondTable}
                                handleRemoveTransactionItem={
                                    handleRemoveTransactionItem
                                }
                                setData={setData}
                            />
                        ),
                    )}
                    {isEdit && editIdSecondTable === null && (
                        <Box display="flex" marginY="20px">
                            <Button
                                sx={{
                                    fontWeight: '600',
                                    fontSize: '14px',
                                    color: '#0061DB',
                                    textTransform: 'none',
                                }}
                                onClick={() =>
                                    handleAddTransactionItem(rowIndex)
                                }
                            >
                                + Transaction Item
                            </Button>
                        </Box>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DropDownTable;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    token: '',
    error: { message: '' },
    successMsg: '',
    data: {},
    logout: false,
};

const prepare = (payload, meta, error) => {
    return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const authSlice = createSlice({
    name: 'auth_me',
    initialState,
    reducers: {
        LOG_IN_PENDING: {
            reducer: (state) => {
                state.loading = true;
                state.error = { message: '' };
            },
            prepare,
        },
        LOG_IN_SUCCESS: {
            reducer: (state) => {
                state.loading = false;
                state.error = { message: '' };
            },
            prepare,
        },
        LOG_IN_ERROR: {
            reducer: (state, { payload }) => {
                state.loading = false;
                state.error.message = payload.error;
            },
            prepare,
        },

        SIGN_UP_PENDING: {
            reducer: (state) => {
                state.loading = true;
            },
            prepare,
        },
        SIGN_UP_SUCCESS: {
            reducer: (state, { payload }) => {
                state.loading = false;
                state.successMsg = payload.successMsg;
                state.error.message = '';
            },
            prepare,
        },
        SIGN_UP_ERROR: {
            reducer: (state, { payload }) => {
                state.loading = false;
                state.error.message = payload.error;
            },
            prepare,
        },

        UPDATE_AUTH_USER: {
            reducer: (state, { payload }) => {
                state.data = { ...state.data, ...payload };
            },
            prepare,
        },
        SAVE_TOKEN: {
            reducer: (state, { payload }) => {
                state.token = payload.token;
            },
            prepare,
        },
        LOGOUT: {
            reducer: (state) => {
                state.loading = false;
                state.token = '';
                state.error.message = '';
                state.logout = true;
            },
            prepare,
        },
        RESET_LOGOUT: {
            reducer: (state) => {
                state.logout = false;
            },
            prepare,
        },
    },
});

export const {
    LOG_IN_PENDING,
    LOG_IN_SUCCESS,
    LOG_IN_ERROR,
    SIGN_UP_PENDING,
    SIGN_UP_SUCCESS,
    SIGN_UP_ERROR,
    SAVE_TOKEN,
    LOGOUT,
    RESET_LOGOUT,
} = authSlice.actions;

export default authSlice.reducer;

import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useCompanyService = () => {
    const { get, post, patch, isLoading } = useAxios();

    const getCurrencyList = useCallback(
        (companyId) => get(`/company/currency_list/${companyId}`),
        [get],
    );

    const getUserAssociatedCompany = useCallback(
        () => get('/company/user/'),
        [get],
    );

    const createCompany = (name, industry, entity, timezone) =>
        post('/company/', { name, industry, entity, timezone }, '');

    const updateCompany = (id, data) =>
        patch(`/company/${id}/update/`, { ...data }, '');

    const createStripeSession = useCallback(
        (companyId) => get(`/company/${companyId}/stripe-portal-url/`),
        [get],
    );

    return {
        isLoading,
        createCompany,
        updateCompany,
        getCurrencyList,
        getUserAssociatedCompany,
        createStripeSession,
    };
};

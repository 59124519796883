/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, useTheme } from '@mui/material';
import {
    Button,
    DataTable,
    Menu,
    Modal,
    NonAccountantMode,
    TablePagination,
    Typography,
} from 'shared';
import { ReactComponent as Source } from 'assets/icons/Source.svg';
import { ReactComponent as SourceDarkMode } from 'assets/icons/SourceDarkMode.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/ExcelIcon.svg';
import { ReactComponent as CSV } from 'assets/icons/CSV.svg';
import { capitalize, formateDate } from 'utils/helpers';
import { useNonAccountantMode } from 'hooks/useNonAccountantMode';
import { useThemeToggle } from 'hooks/useThemeToggle';
import { useCustomMediaQuery } from 'hooks/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTransactionService } from 'store/services/transactions.service';
import { useSelector } from 'react-redux';
import { ChatBox } from '../Chat/ChatBox';
import { useChatService } from 'store/services/chat.service';
import CircularIndeterminate from 'shared/CircularLoader';
import TransactionTabsList from './TabsList';
import ToolbarTransaction from './ToolbarTransaction';
import ImportTransaction from './TabsData/ImportBankTransaction';
import { BankReconciliation } from './TabsData/BankReconciliation';
import { Home } from 'components';

const makeStyles = ({ isMobileScreen }) => ({
    root: {
        backgroundColor: (theme) => theme.backgroundColor.primary,
        p: '20px 40px',
    },
    transactionHeaderMainHeading: {
        color: (theme) => theme.textColor.dark,
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.2px',
    },
    transactionHeaderSecondaryHeading: {
        color: '#6C727F',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
    transactionContent: {
        p: isMobileScreen ? '0px 10px 24px 10px' : '24px 48px 24px 48px',
    },
    transactionTableContainer: {
        p: isMobileScreen ? '0px 10px 24px 10px' : '0px 48px 24px 48px',
    },
});

const transactionTableHeaderCells = [
    {
        id: 'date',
        label: 'Date',
        sort: true,
        verticalAlign: 'top',
    },
    {
        id: 'transaction',
        label: 'Transaction details',
        sort: true,
        verticalAlign: 'top',
    },
    {
        id: 'accountNo',
        label: 'Account No.',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'account',
        label: 'Account',
        sort: true,
        verticalAlign: 'top',
    },
    {
        id: 'debitCredit',
        label: 'Debit / (Credit)',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'amount',
        label: 'Amount',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'currency',
        label: 'Currency',
        sort: false,
        textAlign: 'center',
        verticalAlign: 'top',
    },
    {
        id: 'company',
        label: 'Company',
        sort: false,
        verticalAlign: 'top',
    },
    {
        id: 'status',
        label: 'Status',
        sort: false,
        verticalAlign: 'top',
        textAlign: 'center',
    },
    {
        id: 'source',
        label: 'Source',
        sort: false,
        textAlign: 'center',
        verticalAlign: 'top',
    },
];

export const Transaction = () => {
    const navigate = useNavigate();
    const { data: company } = useSelector((state) => state.company);
    const { getChats, isLoading: isChatLoading } = useChatService();
    const { isLoading, getTransactionsByCompany } = useTransactionService();
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDismiss, setDismiss] = useState(true);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const [excludedColumns, setExcludedColumns] = useState([
        'amount',
        'status',
    ]);
    const styles = makeStyles({ isMobileScreen });
    const { themeMode } = useThemeToggle();
    const { nonAccountantMode } = useNonAccountantMode();

    const [source, setSource] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [isShowSource, setIsShowSource] = useState(false);
    const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
    const selectedValue = useSelector((state) => state.me.selectedItems);

    useEffect(() => {
        if (nonAccountantMode) {
            setExcludedColumns(['debitCredit', 'accountNo']);
        } else {
            setExcludedColumns(['amount', 'status']);
        }
    }, [nonAccountantMode]);

    useEffect(() => {
        const fetchTransactions = async () => {
            if (company?.id && selectedValue === 'Transactions') {
                try {
                    const res = await getTransactionsByCompany(
                        company.id,
                        page,
                    );
                    setTransactions(res);
                } catch (error) {
                    if (error?.statusCode === 404) {
                        setTransactions([]);
                    } else {
                        console.error('An unexpected error occurred:', error);
                        setTransactions([]);
                    }
                }
            }
        };

        fetchTransactions();
    }, [company?.id, getTransactionsByCompany, page, selectedValue]);

    const toggle = () => setIsDateRangeOpen(!isDateRangeOpen);

    const onChangeDateRange = (dateRange) => {
        setIsDateRangeOpen(!isDateRangeOpen);
        console.log({ dateRange });
        setDateRange({
            startDate: `${formateDate(dateRange.startDate)}`,
            endDate: `${formateDate(dateRange.endDate)}`,
        });
    };

    const handleSourceClick = (chatItemId) => {
        setIsShowSource(true);
        getChats(chatItemId).then((chats) => setSource(chats));
    };

    const formattedTableData = () => {
        return transactions.results?.map((transaction) => {
            const status = transaction.is_paid ?? false;
            return {
                date: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={transaction.date}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                        />
                    </Grid>
                ),
                transaction: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={transaction.description}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                        />
                    </Grid>
                ),
                accountNo: (
                    <Grid container flexDirection={'column'}>
                        {transaction.journal_entries.map(
                            (journalEntry, index) => (
                                <Grid
                                    item
                                    disply="flex"
                                    alignItems="center"
                                    key={index}
                                    sx={{
                                        padding:
                                            index !==
                                            transaction.journal_entries.length -
                                                1
                                                ? '0 12px 40px 0px'
                                                : '0 12px 0px 0px',
                                    }}
                                >
                                    <Typography
                                        text={
                                            journalEntry.account.account_number
                                        }
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#121826',
                                        }}
                                    />
                                </Grid>
                            ),
                        )}
                    </Grid>
                ),

                account: (
                    <Grid container flexDirection={'column'}>
                        {nonAccountantMode ? (
                            <Grid
                                item
                                disply="flex"
                                alignItems="center"
                                sx={{
                                    padding: 0,
                                }}
                            >
                                <Typography
                                    text={capitalize(
                                        transaction?.account?.account_name,
                                    )}
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: 14,
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#121826',
                                    }}
                                />
                            </Grid>
                        ) : (
                            transaction.journal_entries.map(
                                (journalEntry, index) => (
                                    <Grid
                                        item
                                        disply="flex"
                                        alignItems="center"
                                        key={index}
                                        sx={{
                                            padding:
                                                index !==
                                                transaction.journal_entries
                                                    .length -
                                                    1
                                                    ? '0 12px 40px 0px'
                                                    : '0 12px 0px 0px',
                                        }}
                                    >
                                        <Typography
                                            text={capitalize(
                                                journalEntry?.account
                                                    ?.account_name,
                                            )}
                                            sx={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#D3D5DA'
                                                        : '#121826',
                                            }}
                                        />
                                    </Grid>
                                ),
                            )
                        )}
                    </Grid>
                ),

                debitCredit: (
                    <Grid container flexDirection={'column'}>
                        {transaction.journal_entries.map(
                            (journalEntry, index) => (
                                <Grid
                                    item
                                    disply="flex"
                                    alignItems="center"
                                    key={index}
                                    sx={{
                                        padding:
                                            index !==
                                            transaction.journal_entries.length -
                                                1
                                                ? '0 12px 40px 0px'
                                                : '0 12px 0px 0px',
                                    }}
                                >
                                    <Typography
                                        text={`${
                                            journalEntry.debit !== '0.00'
                                                ? `${journalEntry.debit}`
                                                : `(${journalEntry.credit})`
                                        }`}
                                        sx={{
                                            fontWeight: 400,
                                            fontSize: 14,
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : '#121826',
                                        }}
                                    />
                                </Grid>
                            ),
                        )}
                    </Grid>
                ),
                amount: nonAccountantMode && (
                    <Grid container flexDirection={'column'}>
                        <Grid
                            item
                            disply="flex"
                            alignItems="center"
                            sx={{
                                padding: 0,
                            }}
                        >
                            <Typography
                                text={` ${transaction.transaction_total}`}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#D3D5DA'
                                            : '#121826',
                                }}
                            />
                        </Grid>
                    </Grid>
                ),
                currency: (
                    <Grid
                        item
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            text={transaction.currency}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                        />
                    </Grid>
                ),
                company: (
                    <Grid item display="flex" alignItems="center">
                        <Typography
                            text={company.name}
                            sx={{
                                fontWeight: 400,
                                fontSize: 14,
                                color: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? '#D3D5DA'
                                        : '#121826',
                            }}
                        />
                    </Grid>
                ),
                status: nonAccountantMode && (
                    <Grid
                        container
                        display="flex"
                        alignItems="center"
                        width={status ? '90%' : '100%'}
                        justifyContent={'center'}
                    >
                        <Box
                            sx={{
                                p: '4px 12px',
                                borderRadius: '1000px',
                                border: `1px solid ${
                                    status ? '#ABEFC6' : '#FECDCA'
                                }`,
                                background: status ? '#ABEFC6' : '#FEF3F2',
                                flex: '1 1 0',
                                display: 'flex',
                                justifyContent: 'center',
                                width: status ? '54px' : '72px',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                text={transaction.is_paid ? 'Paid' : 'Not Paid'}
                                customStyles={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: status ? '#067647' : '#B42318',
                                }}
                            />
                        </Box>
                    </Grid>
                ),
                source: (
                    <Grid
                        item
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleSourceClick(transaction.chat)}
                    >
                        {themeMode === 'dark' ? (
                            <IconButton>
                                <SourceDarkMode />
                            </IconButton>
                        ) : (
                            <IconButton>
                                <Source />
                            </IconButton>
                        )}
                    </Grid>
                ),
            };
        });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setIsShowSource(false);
    };

    const handlePageChange = (e, pageNumber) => {
        setPage(pageNumber);
    };

    const theme = useTheme();
    return (
        <Grid container>
            {/* {isDismiss && (
                <Grid
                    item
                    container
                    sx={styles.root}
                    justifyContent={'space-between'}
                    style={{
                        borderBottom: `1px solid ${
                            theme.palette.mode === 'dark'
                                ? '#394150'
                                : '#E5E6EB'
                        }`,
                    }}
                >
                    <Grid
                        item
                        container
                        spacing={2}
                        xs={isMobileScreen ? 12 : 5}
                        sx={{ marginLeft: isMobileScreen ? '-35px' : '0px' }}
                    >
                        <Grid item xs={12}>
                            <Box>
                                <Typography
                                    text={`You have [${
                                        transactions.count ?? 0
                                    }] pending review.`}
                                    customStyles={
                                        styles.transactionHeaderMainHeading
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                text="Check out the all pending review."
                                customStyles={
                                    styles.transactionHeaderSecondaryHeading
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        xs={isMobileScreen ? 12 : 6}
                        container
                        spacing={2}
                        pt={isMobileScreen ? 1 : 0}
                        justifyContent={
                            isMobileScreen ? 'flex-start' : 'flex-end'
                        }
                        alignItems={'center'}
                        sx={{ marginLeft: isMobileScreen ? '-35px' : '0px' }}
                    >
                        <Grid item>
                            <Button
                                text="Dismiss"
                                size="small"
                                variant="outlined"
                                customStyles={{
                                    background: (theme) =>
                                        theme.backgroundColor.profileSettings,
                                    color: (theme) => theme.textColor.primary,
                                    p: '12px 20px',
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}
                                onClick={() => setDismiss(!isDismiss)}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                text="Review pending items"
                                customStyles={{
                                    backgroundColor: (theme) =>
                                        theme.backgroundColor.warning,
                                    color: '#212936',
                                    p: '12px 20px',
                                    fontSize: 14,
                                    fontWeight: 500,
                                    '&:hover': {
                                        backgroundColor: (theme) =>
                                            theme.backgroundColor.warning,
                                    },
                                }}
                                onClick={() => navigate('/history')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )} */}
            <Grid
                padding={1}
                sx={{
                    width: '100%',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#121826' : 'white',
                    borderBottom: `1px solid ${
                        theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB'
                    }`,
                }}
            >
                <TransactionTabsList />
            </Grid>
            {selectedValue === 'Transactions' ? (
                <Grid sx={{ width: '100%', border: 'none' }}>
                    <ToolbarTransaction />
                </Grid>
            ) : null}
            {/* <Grid item container sx={styles.transactionContent} spacing={2}>
                {isScreenLowerThan1024 && (
                    <Grid item display={'flex'} xs={12} alignItems={'center'}>
                        {<NonAccountantMode label={'Non-accountant Mode'} />}
                    </Grid>
                )}
            </Grid> */}
            {/* {selectedValue === 'Transactions' ? (
                <Grid
                    item
                    xs={12}
                    container
                    mt={2}
                    sx={styles.transactionTableContainer}
                >
                    <Grid item xs={12}>
                        <DataTable
                            showColumn={nonAccountantMode}
                            visibleColumns={[
                                ...transactionTableHeaderCells
                                    .filter(
                                        (header) =>
                                            !excludedColumns.includes(
                                                header.id,
                                            ),
                                    )
                                    .map((column) => column.id),
                            ]}
                            isMobileScreen={isMobileScreen}
                            actionIconPlacement={'top'}
                            data={formattedTableData()}
                            headCells={transactionTableHeaderCells}
                            canEdit={false}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12} p={'10px 0'}>
                        <TablePagination
                            page={page}
                            count={transactions.count}
                            onChange={handlePageChange}
                            isMobileScreen={isMobileScreen}
                        />
                    </Grid>
                </Grid>
            ) : null} */}
            {selectedValue === 'Transactions' ? (
                <Grid item xs={12}>
                    <Home isTransactionsTab />
                </Grid>
            ) : null}

            {selectedValue === 'Bank reconciliation' && (
                <Grid
                    item
                    xs={12}
                    mt={2}
                    container
                    sx={styles.transactionTableContainer}
                >
                    <BankReconciliation />
                </Grid>
            )}
            {selectedValue === 'Scan Receipt' && (
                <Grid item xs={12}>
                    <Home isEdit />
                </Grid>
            )}
            {selectedValue === 'Import transactions' && (
                <Grid
                    item
                    xs={12}
                    mt={2}
                    container
                    sx={styles.transactionTableContainer}
                >
                    <ImportTransaction />
                </Grid>
            )}
            <Menu
                items={[
                    {
                        name: (
                            <Grid container spacing={2}>
                                <Grid item>
                                    <ExcelIcon />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text={'Export as Excel'}
                                        customStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.textColor.primary,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ),
                        onClick: () => {},
                    },
                    {
                        name: (
                            <Grid container spacing={2}>
                                <Grid item>
                                    <CSV />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text={'Export as CSV'}
                                        customStyles={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.textColor.primary,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ),
                        onClick: () => {},
                    },
                ]}
                anchorEl={anchorEl}
                handleClose={handleCloseMenu}
                customStyles={{ width: '254px' }}
            />
            <Modal
                fullWidth
                title={
                    <Typography
                        text={'Source'}
                        customStyles={{
                            fontSize: isScreenLowerThan1024 ? '24px' : '32px',
                            fontWeight: 700,
                        }}
                    />
                }
                cancelBtnText={
                    <Typography
                        text={'Close'}
                        customStyles={{
                            color: '#fff',
                            fontSize: 18,
                            fontWeight: 500,
                        }}
                    />
                }
                showImprovementText={false}
                open={isShowSource}
                handleClose={handleCloseModal}
                customCancelBtnStyle={{
                    backgroundColor: '#0061DB !important',
                }}
            >
                <Grid container justifyContent={'center'}>
                    {isChatLoading ? (
                        <Grid item>
                            <CircularIndeterminate />
                        </Grid>
                    ) : (
                        <Grid item width={'100%'}>
                            <ChatBox chatBoxMessage={source} />
                        </Grid>
                    )}
                </Grid>
            </Modal>
        </Grid>
    );
};

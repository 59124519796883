import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const FullWidthTabs = ({
    tabs,
    width = '100%',
    variant,
    tabCustomStyles,
}) => {
    const theme = useTheme();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Box sx={{ width }}>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    bgcolor: (theme) => theme.backgroundColor.primary,
                    '& .Mui-selected': {
                        bgcolor: '#0061DB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? theme.textColor.dark
                                : `${theme.backgroundColor.primary} !important`,
                        borderRadius: '10px',
                    },
                    color: (theme) => theme.textColor.dark,
                    borderRadius: '10px',
                    marginBottom: 2,
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant={variant}
                    aria-label="tabs"
                    scrollButtons
                    allowScrollButtonsMobile
                    TabIndicatorProps={{
                        style: { display: 'none' },
                    }}
                    sx={{
                        p: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                ...tabCustomStyles,
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </AppBar>
            <Box
                sx={{
                    borderRadius: '12px',
                    bgcolor: (theme) => theme.backgroundColor.primary,
                    m: '8px 0 8px 0px',
                    p: '3px',
                }}
            >
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    style={{}}
                >
                    {tabs.map((tab, index) => (
                        <TabPanel
                            key={index}
                            value={value}
                            index={index}
                            dir={theme.direction}
                        >
                            {tab.content}
                        </TabPanel>
                    ))}
                </SwipeableViews>
            </Box>
        </Box>
    );
};

FullWidthTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            content: PropTypes.node.isRequired,
        }),
    ).isRequired,
};

import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useAuthService = () => {
    const { get, post, patch, isLoading } = useAxios();
    const getLoggedInUserDetails = useCallback(
        (impersonatedUserId?: string) => get('/user/profile/'),
        [get],
    );

    const login = (email, password) =>
        post('/user/login/', { email, password }, '', false);

    const signup = (email, password, phoneNumber) =>
        post('/user/register/', { email, password, phoneNumber }, '', false);

    const updateUser = (user) => patch('/user/profile/update/', user, '');

    const resetPassword = (email) => {
        post('/user/password-reset/', { email }, '');
    };

    const toggleDarkMode = (theme) => post('user/toggle/darkmode/', {}, '');

    const updateUserDemo = (data) => post('/user/set/demo/', null, '');

    return {
        login,
        signup,
        isLoading,
        updateUser,
        toggleDarkMode,
        updateUserDemo,
        resetPassword,
        getLoggedInUserDetails,
    };
};

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import SwipeableViews from 'react-swipeable-views';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const HomeTabs = ({
    tabs,
    width = '100%',
    variant,
    tabCustomStyles,
    tabValue,
    setTabValue,
}) => {
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setTabValue(index);
    };

    return (
        <Box sx={{ width }}>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    bgcolor: (theme) => theme.backgroundColor.primary,
                    '& .Mui-selected': {
                        bgcolor: '#0061DB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? theme.textColor.dark
                                : `${theme.backgroundColor.primary} !important`,
                        borderRadius: '10px',
                    },
                    color: (theme) => theme.textColor.dark,
                    borderRadius: '10px',
                    marginBottom: 2,
                }}
            >
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant={variant}
                    aria-label="tabs"
                    scrollButtons
                    allowScrollButtonsMobile
                    TabIndicatorProps={{
                        style: { display: 'none' },
                    }}
                    sx={{
                        p: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        '& .MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            label={tab.label}
                            sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                ...tabCustomStyles,
                            }}
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </AppBar>
            <Box
                sx={{
                    borderRadius: '12px',
                    bgcolor: (theme) => theme.backgroundColor.primary,
                    m: '8px 0 8px 0px',
                    p: '3px',
                }}
            >
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={tabValue}
                    onChangeIndex={handleChangeIndex}
                    style={{}}
                >
                    {tabs.map((tab, index) => (
                        <TabPanel
                            key={index}
                            value={tabValue}
                            index={index}
                            dir={theme.direction}
                        >
                            {tab.content}
                        </TabPanel>
                    ))}
                </SwipeableViews>
            </Box>
        </Box>
    );
};
